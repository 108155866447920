.addTkp-navbar{
    margin-top: 20px;
    padding: 20px;
    background-color: rgb(248, 248, 248);

}

.add-tkp-text {
   margin-top: 5px;
   margin-left: 10px;
}

.button{
    font-size: 15px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
    background-color: white;
    margin-left: 40px;
    font-weight: bold;
    border: 0;
    outline: 0;
}
