/* .menubar-layout{
        display: flex;
        flex-direction: row;
} */

/* .main{
   background-color: hsl(0, 0%, 89%);
   width:100%;
} */

/* Addding new search bar styles to inside layouts */
/* div{
        position: relative;
    } */
    /* .searchField-chield{
        width: 375px;
        padding: 7px 7px 7px 45px;
        border: 1px solid #eee !important;
    } */
    .searchIcon-chield{
        position: absolute;
        left: 20px;
        top: 10px;
    }
    .clearIcon{
        position: absolute;
        right: 10px;
        top: 10px;

    }