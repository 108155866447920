.contentwrapper {
    padding: 10px;
}

.stepswrapper {
    height: 200px;
    margin-top: 20px;
}

.stepswrapper h4 {
    text-align: center;
    margin-bottom: 50px;
}

.image-wrapper {
    margin: 0;
}

.image-wrapper .main-image {
    height: 340px;
    border-radius: 5px;
    margin-top: 10px;
}

.main-image img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.secondary-images .col {
    padding: 0;
    width: 100px;
    height: 90px;
    margin-left: 10px;
    margin-right: 3px;
}

.secondary-images .col img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.small-image {
    width: 300px;
    height: 150px;
}

.small-image img {
    width: 100%;
    height: 100%;
}

.last-image .overlay {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    top: -90px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(124, 123, 123, 0.5);
    z-index: 2;
    cursor: pointer;
    border-radius: 20px;
}

.last-image .morecontent {
    color: white;
    position: relative;
    top: -145px;
    text-align: center;
    z-index: 3;
    font-size: 15px;
    font-weight: 500;

}

/* .image-wrapper:hover .edit-wrapper{
    display: block;
    position: relative;
    bottom: -28px;
    text-align: right;
    padding-right: 30px;
    cursor: pointer;
} */
/* .allocateddetails{
    padding: 10px;
    height: 200px;
}
.allocateddetails .ant-card{
    height: 150px;
    border-radius: 10px;
    margin-right: 3px;
} */
/* .projectImagesBlock{
    position: relative;
    
} */

.scheduleInfo {
    /* background-color: yellowgreen; */
    position: relative;
}

/* .scheduleInfo:hover .editPencil{

    display: block;
    width: 10px;
    position: absolute;
    top: -1px;
    right:-12px;
    text-align: right;
    padding-right: 30px;
    cursor: pointer;
} */

.upcoming {
    margin-top: 25px;
}

.ongoing {
    margin-top: 25px;
}

.map-wrapper {
    width: 400px;
    height: 150px;
    border-radius: 10px;
}

.map-wrapper .leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.map-wrapper .leaflet-marker-pane .leaflet-marker-icon{
    width: 30px !important;
    height: 50px !important;

}


.editPM {
    cursor: pointer;
}
.ant-notification-notice-message{
    color: white
}


.allocatedName {
    color: #f8a504;
    font-size: 14px;
    font-weight: 600;

}

.allocatedDesignation {
    font-style: 'Arial, sans-serif';
    font-size: 14px;
    color: #676766;
    border-width:1px;
    padding: 5px;
    line-height: 1.5; 
    font-weight: "bold";
}


.logBackground {
    background-color: #fff8eb;
    height: 28px;
    border-radius: 5px;
    border: 2px dashed #FDE9C3;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.allocatedLogDate {
    color: #676766;
    font-size: 10px;
}

.ant-modal-footer  .ant-btn{
    border-radius: 6px;
 }

.reallocateBtn {
    background-color: #f8a504;
    color:#fff;
    font-weight: bold;
}

.reallocateBtn:hover {
    border-color: #f8a504;
    background-color: #fff;
    color: #f8a504;
}

.cancelBtnR:hover {
    border-color: #b40000;
    color: #b40101;
}

.logHistoryButton {
    background-color: #ebebeb !important;
    border: 1px solid #bdbdbd;
    color: #212121;
}

.logHistoryButton:hover {
    background-color: #bfbfbf !important;
    border: 1px solid #9a9a9a;
}