/* ======= Google Fonts ======= */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap');

/* ======= Glogal ======= */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
    font-size: 13px;
    letter-spacing: 0.2px;
    outline: none;
}

.form-control:focus {
    box-shadow: unset;
    border-color: #ffc169;
}

body {
    overflow: hidden;
}

.fW500 {
    font-weight: 500;
}

textarea {
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    padding: 7px 12px;
}

.row {
    padding-bottom: 20px;
}

.row:last-child {
    padding-bottom: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-dataRow {
    margin-top: 20px;
}

.form-select:focus {
    border-color: #ffc169;
    box-shadow: unset;
}

textarea:focus {
    border-color: #ffc169;
}

/* ======= Variables styles ======= */

$primaryColor: #f8a504;

$whiteColor: #fff;
$blackColor: #000;

$lightColor: #ccc;
$thickColor: #eee;
$darkColor: #777;

$grayColor: #ddd;
$darkGrayColor: #aaa;

$bgColor: #f4f5f9;

$borderRadius: 7px;

$titleLg: 25px;

$link: #028aff;

$info: #17a2b8;

$success: #28a745;

$danger: #dc3545;

$warning: #ffc107;


$cold: #c89722;

$hot: #64a00e;

$future: #23a3b6;

$discarded: #737373;

$lost: #ff3e3e;

$won: #a6a82e;

$Draft: #373d42;

$Processing: #007281;

$Pending: #ab7400;

$Rejected: #e42a33;

$Approved: #00a43b;


/* ======= Variables styles end ======= */

/* ======= Scrollbar styles ======= */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: $darkColor;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: $whiteColor;
}

/* ======= Scrollbar styles end ======= */

/* ======= Signin styles ======= */

.whSigninBg,
.whSignupBg {
    margin: 0;
    height: 100vh;
    padding: 0;
    background: url(../../Assets/images/wehouse-signin-bg.jpg);
    background-size: cover;
    background-position-x: -320px;
    background-repeat: no-repeat;
}

.whSigninPanel,
.whSignupPanel {
    width: 650px;
    height: 100vh;
    background-color: $whiteColor;
    position: absolute;
    left: calc(100% - 650px);
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    padding: 70px 130px;
    -webkit-box-shadow: 1px 0px 25px 4px rgb(0 16 67);
    -moz-box-shadow: 1px 0px 25px 4px rgb(0, 16, 67);
    box-shadow: 1px 0px 25px 4px rgb(0 16 67);
}

.whSigninBgOverlay,
.whSignupBgOverlay {
    background: rgba(47, 30, 0, 0);
    height: 100vh;
}

.whSiginContainer,
.whSigupContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .whIcon {
        margin: 40px 0 70px 0;
        display: flex;
        justify-content: center;

        img {
            display: flex;
            margin: 0 auto;
        }
    }

    p,
    small{
        color: #ff5500;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
    }
}
.emailExist{
    color: rgb(36,130,33);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
}

.whSigupContainer {
    .whIcon {
        margin: 0px 0 50px 0px;
    }
}

.signinToggleBtns {
    background: $bgColor;
    border-radius: $borderRadius;
    display: flex;
    margin-bottom: 35px;

    >.btn {
        width: 100%;
        margin: 5px 7px;
        height: 40px;
        font-size: 13px;
        font-weight: 500;
    }

    /* Style the active class, and buttons on mouse-over */
    .active,
    .btn:hover {
        background: $whiteColor !important;
        box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 7%);
    }
}

@mixin inputType {
    height: 40px;
    border: 1px solid $grayColor;
    // background: $bgColor;  
    font-size: 13px;
    border-radius: $borderRadius;
    padding: 7px 12px;
}

input[type] {
    @include inputType;
}

input[type='time'],
input[type='date'] {
    width: 100%;
}

.contentBody select {
    @include inputType;
}

input[type]:focus {
    font-size: 13px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid $grayColor;
    -webkit-box-shadow: 0 0 0px 1000px $bgColor inset;
}

.mobileField,
.number-group {
    .mobileNumField {
        display: flex;
    }

    .number,
    .phone-field {
        width: 100%;
        border-radius: 0 $borderRadius $borderRadius 0;
        border: 1px solid $grayColor;
        border-left: none;
        padding: 10px 10px 10px 0px;
    }

    .PhoneInputInput {
        width: 38px !important;
        padding: 4px 7px !important;
        border: 1px solid $grayColor !important;
        border-right: none !important;
        border-left: none !important;
        border-radius: unset;
    }

    .PhoneInputCountry {
        border: 1px solid $grayColor !important;
        width: 50px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-right: 0px;
    }

    .PhoneInputCountryIcon {
        margin-left: 10px;
    }
}

.titleLg {
    font-size: $titleLg;
    font-weight: 700;
}

@mixin submitBtn() {
    width: 100%;
    height: 40px;
    background-color: $primaryColor;
    color: $whiteColor;
    font-size: 13px;
    font-weight: 500;
    border-radius: $borderRadius;
    border: unset;
    -webkit-box-shadow: 0px 7px 20px -3px rgba(248, 165, 4, 0.5);
    -moz-box-shadow: 0px 7px 20px -3px rgba(248, 165, 4, 0.5);
    box-shadow: 0px 7px 20px -3px rgba(248, 165, 4, 0.5);
}

.signInBtn {
    @include submitBtn;
    margin-top: 40px;
    margin-bottom: 40px;
}

label {
    font-size: 13px;
    font-weight: 400;
    color: $darkGrayColor;
    margin-bottom: 7px !important;
}

.inputField {
    margin-top: 20px;
}

.pswdField {
    position: relative;

    img {
        height: 20px;
        position: absolute;
        right: 15px;
        top: 10px;
        color: $darkGrayColor;
        opacity: 0.3;
        cursor: pointer;
    }
}

.forgotPswd {
    display: flex;
    justify-content: space-between;

    a {
        font-style: italic;
        color: $darkGrayColor;
        font-size: 13px;
        top: 2px;
        position: relative;
    }

    a:hover {
        color: $primaryColor;
    }
}

.forgot-btn {
    margin-left: 35%;
}

/* ======= Signin styles end ======= */

/* ======= Forgot styles ======= */

.whForgotContainer {
    max-width: 390px;
    margin: 100px auto 0 auto;
}

.forgotBtn {
    @include submitBtn;
    margin-top: 30px;
    width:30%;
}

.forgotField {
    margin-top: 100px;
}

.backToSignIn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.reset-error-msg {
    color: red;
}

.reset-success-msg {
    color: green;
}

/* ======= Forgot styles end ======= */

/* ======= Signup styles ======= */

.signUpDOB {
    select {
        width: 108px;
        padding: 0 4px;
    }
}

.genderField {
    @include inputType;

    .radioBtnField {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;

        input[type="radio"] {
            cursor: pointer;
        }
    }
}

.termsInfo {
    margin: 20px 0 !important;
    font-size: 12px !important;
}

.termsInfo.signInLink {
    text-align: center;
    margin-bottom: 50px !important;
}

.captcha {
    margin-bottom: 20px;
}

.signUpBtn {
    @include submitBtn;
}

/* ======= Signup styles end ======= */


/* ======= Verify styles ======= */

.otpDigitGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .otpField {
        width: 40px;
        text-align: center;
        border: 1px solid #fff5e4;
        box-shadow: 0px 3px 4px 0px rgb(252 168 12 / 13%);
    }
}

.verifyOtpBtn {
    @include submitBtn;
    margin-top: 40px;
    margin-bottom: 40px;
}

.resendOtp {
    text-align: center;
    margin-bottom: 40px;
}

/* ======= Verify styles end ======= */


/* ======= Top Navbar styles ======= */
.whtopNavLogo {
    margin-left: 10px;
}

.topNavbar {
    height: 65px;
    z-index: 99;
    box-shadow: 0 1px 12px 0px rgb(0 0 0 / 12%);
}

.serachInput {
    div {
        position: relative;
    }

    .searchField {
        // width: 300px;
        width: 45px;
        height: 42px;
        margin-left: 100px;
        padding: 7px 7px 7px 36px;
        border: 1px solid $thickColor !important;
        background: #fff url(../../Assets/images/search.png) no-repeat;
        background-position: 12px 10px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
        cursor: pointer;
       
    }

    input[type=text].searchField:focus {
        width: 100%;
    }

    // input[type=text].searchField::target-text{
    //     width: 100%;
    // }
    .searchIcon {
        position: absolute;
        left: 115px;
        top: 10px;
    }

    .clearIcon {
        position: absolute;
        right: 10px;
        top: 10px;

    }
}

.searchIconContainer {
    margin-left: 10px;
}

.navbar-nav {
    li {
        padding: 0 15px;
    }

    .navIcon {
        width: 40px;
        height: 40px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navIcon:hover {
        background: $primaryColor;

        svg {
            fill: $whiteColor;
        }
    }
}

.blankAvatarImg {
    background: url(../../Assets/images/blankAvatar.jpg) no-repeat;
    height: 40px;
    width: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid #eee;
    border-radius: 10px;
    outline: unset;
    text-indent: -99999px;
}


input[type="file"] {
    display: none;
}

.cardBlankAvatarImg {
    background: url(../../Assets/images/cardBlankAvatar.jpg) no-repeat;
    height: 150px;
    width: 150px;
    min-width: 150px;
    min-height: 150px;
    border: 1px solid #eee;
    border-radius: 50%;
    outline: unset;
    text-indent: -99999px;
}

.imagePicker {
    display: flex;
    justify-content: center;
}

.userNameTitle {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    // background: orange;
}

/* ======= Top Navbar styles end ======= */


/* ======= Left SideNavbar styles ======= */
.leftSidePanel {
    ul.ant-menu-root {
        overflow: hidden auto;
        height: calc(100vh - 65px) !important;
        box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%) !important;
    }

    .ant-menu-inline .ant-menu-submenu-title {
        padding-right: 10px;
        background: #f4f5f9;
        border-radius: 10px;
        height: 45px;

        .ant-menu-submenu-arrow {
            color: $blackColor;
        }
    }

    .ant-menu-submenu-title {
        color: $blackColor;
    }

    .ant-menu-submenu-title:hover {
        color: $blackColor;
    }

    .ant-menu-title-content {
        color: $blackColor;
    }

    .ant-menu-submenu-selected {
        color: $primaryColor;
    }

    .ant-menu-submenu-active:hover {
        color: $blackColor;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: $primaryColor;
        border-radius: 10px;
    }

    .ant-menu-sub.ant-menu-inline {
        background: $whiteColor;
    }

    .ant-menu-sub.ant-menu-inline>.ant-menu-item {
        border-radius: 10px;
        padding-left: 40px !important;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background: #fbf1df !important;
        border-radius: 10px;
    }

    .ant-menu-submenu-title .ant-menu-item-icon {
        width: 25px;
        font-size: 17px !important;
        margin-left: -4px !important;
    }

    .ant-menu-submenu-title .ant-menu-item-icon+span {
        margin-left: 13px;
        font-weight: 700;
    }

    .ant-menu-item a:hover {
        color: $primaryColor;
    }

    .ant-menu-item-selected {
        svg {
            color: #fff !important;
        }

        a {
            color: $whiteColor;
        }

        a:hover {
            color: $whiteColor;
        }
    }

    .ant-menu-item-selected.ant-menu-item:hover {
        svg {
            color: $whiteColor !important;
        }
    }

    .ant-menu-item:hover {
        svg {
            color: $primaryColor !important;
        }
    }

    .ant-menu-submenu {
        margin: 0 10px;
    }

    .ant-menu-item a,
    .ant-menu-title-content {
        font-weight: 500;
        font-size: 13px;
    }

    .ant-menu.ant-menu-sub {
        .svg-inline--fa {
            font-size: 20px;
            color: $blackColor;
            width: 20px;
            margin-right: 15px;
        }
    }

    .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 10px;
    }

    .ant-menu-inline .ant-menu-submenu-title {
        margin: 3px 0;
    }

    .svg-inline--fa {
        vertical-align: middle;
    }

    .sidebarHead {
        height: 50px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .headTitle {
            display: flex;
        }

        .showTitle {
            font-size: 16px;
            font-weight: 600;
            width: 230px;
            display: flex;
            align-items: center;
        }

        .hideTitle {
            display: none;
        }

        .hideTitle+svg {
            margin-left: 10px;
        }
    }
}

// Left side collapsed menus
.ant-menu-submenu-popup {
    >.ant-menu {
        padding: 0 10px;
        margin-left: 17px;
        border-radius: 10px;
    }

    .ant-menu-item-selected {
        background: $primaryColor !important;
        border-radius: 10px;
    }

    .ant-menu-item-selected a {
        color: $whiteColor;
    }

    .svg-inline--fa {
        font-size: 20px;
        width: 20px;
        margin-right: 20px;
    }

    .ant-menu-item:hover,
    .ant-menu-item a:hover {
        color: $primaryColor;
    }

    .ant-menu-item-selected a:hover {
        color: $whiteColor;
    }

    .ant-menu-item-selected {
        color: $whiteColor;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background: #fbf1df !important;
        border-radius: 10px;
    }

    .ant-menu-item:active .ant-menu-item-selected,
    .ant-menu-item:active .ant-menu-item-selected a {
        color: $primaryColor;
    }

    .svg-inline--fa {
        color: $blackColor;
    }

    .ant-menu-item-selected .svg-inline--fa,
    .ant-menu-item-selected:hover .svg-inline--fa {
        color: $whiteColor;
    }

    .ant-menu-item:hover .svg-inline--fa {
        color: $primaryColor;
    }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: unset;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    padding: 0 14px;
    background: #f4f5f9;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    margin: 3px auto;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title:hover {
    background: $primaryColor;
    color: $whiteColor;
}

/* ======= Left SideNavbar styles end ======= */

/* ======= Content Header styles ======= */
.whAppLayout {
    z-index: 98;
    display: flex;
    margin-top: 0px;
    padding-top: 65px;
}

.whAppContent {
    width: 100%;
    background: $bgColor;
}

.contentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $whiteColor;
    height: 55px;
    padding: 0 30px;
    border-bottom: 1px solid $thickColor;
    box-shadow: 0 1px 12px 0px rgb(0 0 0 / 7%);

    .headerTitle {
        font-size: 16px;
        font-weight: 600;
    }
}

/* ======= Content Header styles end ======= */

/* ======= Content Block styles ======= */
.whContentBlock {
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: calc(100vh - 115px);

    .contentBody {
        margin: 20px;
        border-radius: 10px;
        background: $whiteColor;
        border: 1px solid $thickColor;
        box-shadow: 0 1px 12px 0px rgb(0 0 0 / 5%);
        margin-bottom: 10px;
    }

    .contentNavbar {
        display: flex;
        padding: 15px 20px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eee;

        .serachInput {
            .searchField {
                margin-left: 0px;
            }

            .searchIcon {
                left: 15px;
            }
        }
    }
}

.whAppTable {
    .tableList {

        .tableRow,
        .tableHead {
            width: 100%;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
        }

        .tableRow {
            border-top: 1px solid $thickColor;
            padding: 0 15px;
            cursor: pointer;
        }

        .tableHead {
            border-bottom: 1px solid $grayColor;

            .listItem {
                text-transform: uppercase;
                font-weight: 600;
                color: $darkColor;
                letter-spacing: 0.5px;
            }

            .tableRow {
                border-top: unset;
            }
        }

        .listItem {
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
            padding: 15px;
            color: #777;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
            flex: 1 0 0%;
            flex-shrink: unset;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            justify-content: center;

            .actionIcons {
                img {
                    height: 13px;
                    margin: 0 10px 0 0;
                }
            }

            &.checkBox {
                flex-grow: 0.3;
                align-items: center;
            }

            .btn.activeBtn {
                background: #18b40d;
            }

            .btn.evaluateBtn {
                background: #7da4fa;
            }

            .btn.Active {
                background: #18b40d;
            }

            .btn.Inactive {
                background: #747474;
            }

            .btn.Deleted {
                background: #ff5555;
            }

            .blankAvatarImg {
                margin-right: 12px;
            }
        }

        .alignItemsC {
            align-items: center;
        }

        .personName {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
                font-weight: 600;
                color: $blackColor;
                text-transform: capitalize
            }
        }

        .nameEmailItem {
            display: inline-grid;

            span {
                font-weight: 600;
                color: $blackColor;
                text-transform: capitalize;
            }

            span.personEmail {
                font-weight: 400;
                color: $darkGrayColor;
                display: block;
                font-size: 13px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: auto;
                text-transform: none;
            }
        }
    }

    .fa-star {
        color: $thickColor;
    }

    .fa-star.checked {
        color: $primaryColor;
    }
}

.btn.bronze,
.btn.platinum,
.btn.gold,
.btn.silver,
.btn.diamond {
    font-size: 10px;
    font-weight: 500;
    padding: 3px 10px;
    border-radius: 20px;
    color: #fff;
    letter-spacing: 0.5px;
    margin: 7px 0;
    display: inline-block;
}

.btn.bronze {
    background: #fd8423;
}

.btn.platinum {
    background: #f8a504;
}

.btn.gold {
    background: #ffbc0a;
}

.btn.silver {
    background: #adadad;
}

.btn.diamond {
    background: #2e4e83;
}

.nameInitial {
    width: 40px;
    height: 40px;
    background: #ffead9;
    color: #ff790f;
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.toggleBtn {
    display: flex;
    background: $bgColor;
    border-radius: 7px;
    padding: 10px 0;
    cursor: pointer;

    .toggleBtnIcon {
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .vLine {
        border: 1px solid $grayColor;
        height: 20px;
    }
}

.btn.btnDefault {
    padding: 10px 10px;
    background: #f4f5f9;
    border-radius: 7px;
    height: 40px;
    width: 40px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.groupBtns {
    display: flex;

    .btn {
        margin-left: 10px;
    }
}

button svg {
    width: 20px;
}


.btn.rounded-pill {
    // max-width: 90px;
    background-color: grey;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    cursor: unset;
    padding: 5px 15px;
}

.btn.prospectBtn {
    background: #ff676b;
}

.btn.approvedBtn {
    background: #24bda5;
}

.btn.rejectedBtn {
    background: #f54a2f;
}

.btn.pendingBtn {
    background: #fcb834;
}

/* ======= Content Block styles end ======= */


/* ======= No data found styles ======= */

.noDataFoundBox {
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 115px);
    width: calc(100vw - 80px);

    span {
        font-size: 20px;
        color: $primaryColor;
    }
}

/* ======= No data found styles end ======= */

/* ======= Loader styles ======= */
.loaderBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 115px);
    width: calc(100vw - 80px);
}

.loaderEllipsis {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderEllipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loaderEllipsis div:nth-child(1) {
    left: 8px;
    animation: loaderEllipsis1 0.6s infinite;
    background: #f8a504;
}

.loaderEllipsis div:nth-child(2) {
    left: 8px;
    animation: loaderEllipsis2 0.6s infinite;
    background: #000000;
}

.loaderEllipsis div:nth-child(3) {
    left: 32px;
    animation: loaderEllipsis2 0.6s infinite;
    background: #f8a504;
}

.loaderEllipsis div:nth-child(4) {
    left: 56px;
    animation: loaderEllipsis3 0.6s infinite;
    background: #000000;
}

@keyframes loaderEllipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes loaderEllipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes loaderEllipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

/* ======= Loader styles end ======= */


/* ======= Detail Content styles ======= */

.contentBody.detail {
    background: unset;
    border: unset;
    box-shadow: unset;

    .whCard {
        border-radius: 10px;
        background: #fff;
        border: 1px solid #eee;
        box-shadow: 0 1px 12px 0px rgb(0 0 0 / 10%);
        ;
    }

    .cardHeader {
        padding: 15px 15px;
        margin-bottom: 0;
        background-color: #f8f8f9;
        border-bottom: 1px solid #eee;
        font-size: 17px;
        font-weight: 500;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            margin-left: 7px;
        }

        >span {
            font-size: 15px;
        }
    }

    .cardHeader:first-child {
        border-radius: 10px 10px 0 0;
    }

    .profileCardBody {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 30px 15px;
    }

    .cardBody {
        display: flex;
        flex-flow: column;
        padding: 30px 15px;
    }

    .uploadLabel {
        width: 200px;
        max-width: 200px;
        height: 40px;
        font-size: 13px;
        background: #f8a505;
        color: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.rating {
    .text {
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        width: 350px;
        line-height: 30px;
    }

    .overallScore {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        height: 200px;

        span {
            display: block;
            font-size: 14px;
            font-weight: 500;
        }

        span.rating {
            font-size: 40px;
        }
    }
}

/* ======= Detail Content styles end ======= */

/* ======= Button styles ======= */

@mixin whBtn() {
    height: 40px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
}

.btn:hover {
    color: $blackColor;
}

.btn:hover .btnText a {
    color: $blackColor;
}

.whBtnPrimary {
    @include whBtn;
}

.whBtnPrimary:hover {
    background-color: #f8a504;
    border-color: transparent
}

.saveBtn {
    @include whBtn;
         
            min-width: 70px;
    background-color: #00ac69;
}

.saveBtnclose {
    @include whBtn;
        
            min-width: 70px;
    background-color: #0c8221;
}

.addtask {
         
            @include whBtn;
    min-width: 70px;
    background-color: #5b6b92;
}

.cancelBtn {
        
            @include whBtn;
    background-color: #f8f9fa;
    color: $blackColor;
    margin-right: 15px;
}

.cancelBtn:hover {
    color: #ff3e3e;
}

.btnText {
    a {
        color: $whiteColor;
    }

    svg {
        position: relative;
        top: -1px;
    }
}

@mixin outlineBtn() {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    padding: 5px 20px;
}


.btn.Cold {
    @include outlineBtn;
}

.btn.Cold {
    color: $cold;
    border-color: $cold;
}

.btn.Cold-active {
    color: $whiteColor;
    background: $cold;
}

.btn.Hot {
    @include outlineBtn;
}

.btn.Hot {
    color: $hot;
    border-color: $hot;
}

.btn.Hot-active {
    color: $whiteColor;
    background: $hot;
}

.btn.Future {
    @include outlineBtn;
}

.btn.Future {
    color: $future;
    border-color: $future;
}

.btn.Future-active {
    color: $whiteColor;
    background: $future;
}

.btn.Discarded,
.btn.Discarded {
    @include outlineBtn;
}

.btn.Discarded,
.btn.Discarded {
    color: $discarded;
    border-color: $discarded;
}

.btn.Discarded-active {
    color: $whiteColor;
    background: $discarded;
}

.btn.Lost {
    @include outlineBtn;
}

.btn.Lost {
    color: $lost;
    border-color: $lost;
}

.btn.Lost-active {
    color: $whiteColor;
    background: $lost;
}

.btn.Won {
    @include outlineBtn;
}

.btn.Won {
    color: $won;
    border-color: $won;
}

.btn.Won-active {
    color: $whiteColor;
    background: $won;
}

.btn.Schedule.Call {
    @include outlineBtn;
    padding: 0.25rem 0.5rem;
}

.btn.Schedule.Call {
    color: #73c400;
    border-color: #73c400;
    border-radius: 7px;
    font-size: .875rem;
}

.btn.Schedule.Call-active {
    color: $whiteColor;
    background: #73c400;
    border-radius: 7px;
    padding: 5px 10px;
}

.btn.Schedule.Meeting {
    @include outlineBtn;
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
}

.btn.Schedule.Meeting {
    color: #306fc5;
    border-color: #306fc5;
    border-radius: 7px;
}

.btn.Schedule.Meeting-active {
    color: $whiteColor;
    background: #306fc5;
    border-radius: 7px;
}

.btn.Add.Task {
    @include outlineBtn;
}

.btn.Add.Task {
    color: #c15cc2;
    border-color: #c15cc2;
}

.btn.Add.Task-active {
    color: $whiteColor;
    background: #c15cc2;
}

.btn.allocate {
    @include outlineBtn;
}

.btn.allocate {
    color: #23a3b6;
    border-color: #23a3b6;
}

.btn.Allocate-active {
    color: $whiteColor;
    background: #23a3b6;
}

.modalFooter {
    .btn {
        margin-left: 10px;
    }
}


.btn.Draft {
    background: #d3d5d8;
    color: #373d42;
}

.btn.Processing {
    background: #bbebf2;
    color: #007281;
}

.btn.Pending {
    background: #efe0bd;
    color: #ab7400;
}

.btn.Rejected {
    background: #f7c9cb;
    color: #e42a33;
}

.btn.Approved {
    background: #c4eed3;
    color: #00a43b;
}

.applyFilterBtn {
    padding: 5px 15px;
    border-radius: 7px;
    border: 1px solid $thickColor;
    background: $whiteColor;
    color: $darkColor;

    &:hover {
        background: #f8a504 !important;
        color: #fff;
        border: 1px solid #f8a504;
    }
}

.rightActionBtns,
.leftDefaultBtns {
    display: flex;
    height: 42px;
    gap: 10px;
}

.dateSelector {
    padding: 0 40px 0 15px;
    font-weight: 500;
    border-radius: 7px;
    border: 1px solid $thickColor;
    background: $whiteColor;
    color: $darkColor;
    position: relative;

    .calendarIcon {
        height: 15px;
        position: absolute;
        top: 11px;
        right: 9px;
        opacity: 0.3;
    }

    input {
        background: #fff;
        border: unset;
    }
}

.dashboradDateSelector {
    .calendar {
        .rdrDateRangePickerWrapper {
            top: 4px;
            right: -40px;
        }
    }
}

.toggleBtnIcon.ActiveIcon {
    background: unset !important;

    svg {
        fill: #f69533;
    }
}

.ActiveIcon {
    background: $primaryColor !important;

    svg {
        fill: #fff;
    }
}

.btn.btnDefault:hover,
.toggleBtn:hover {
    background: $primaryColor !important;

    svg {
        fill: #fff;
    }
}

/* ======= Button styles end ======= */


/* ======= Custom Modal styles ======= */
.customModal {
    width: 775px !important;

    .ant-modal-body {
        padding: 25px 25px 35px 25px;
    }

    .ant-modal-content {
        border-radius: 10px;
        overflow: hidden;
    }

    .ant-modal-title {
        font-size: 19px;
        line-height: 30px
    }

    .ant-modal-close-x {
        width: 60px;
        font-size: 32px;
        line-height: 42px;
        color: $blackColor;
    }

    .leadDetails {
        h4 {
            margin-bottom: 3px;
        }

        >div {
            color: $darkGrayColor;

            span {
                margin-right: 4px;
            }
        }

        a {
            text-decoration: 2px underline $link;
            display: inline-block;
        }
    }

    .leadbtns {
        display: flex;
        flex-wrap: wrap;

        .btn {
            margin-right: 15px;
        }
    }

    .modalFooter {
        margin: 25px 0 10px 0;
        display: flex;
        justify-content: flex-end;
    }
}

.ant-picker {
    width: 100%;
    height: 40px;
    border-color: $lightColor;
    box-shadow: unset;
    border-radius: 7px;
    margin-right: 15px;
}

.ant-picker-focused {
    border-color: #ffce70;
    box-shadow: unset;
    border-radius: 7px;
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: #ffce70;
    border-right-width: 1px;
}

.ant-upload.ant-upload-select-picture-card,
.ant-upload.ant-upload-drag {
    border-radius: 7px;
    width: 100%;
    border: 2px dashed $lightColor;
    height: 100px;

    &:hover {
        border-color: #ffce70 !important;

        .upload-wrapper {
            color: $primaryColor;
        }
    }
}

.upload-wrapper {
    display: flex;
    flex-direction: column;
}

.dtPicker>div {
    display: flex;
}

.ant-upload-list-picture-card-container {
    width: 70px;
    height: 70px;
}

.ant-upload-drag-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0 !important;
}

.ant-upload-list-item {
    margin-bottom: 8px;
}

.addTaskWrapper {
    svg {
        font-size: 17px;
    }

    h4 {
        margin-bottom: 0;
    }

    cursor: pointer;
}

.rsw-editor {
    margin-bottom: 20px;
}

/* ======= Custom Modal styles end ======= */


/* ======= Tab styles  ======= */

.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
    padding: 0 30px;
    background: #fff;
}

.ant-tabs-tab {
    padding: 19px 20px;
    font-weight: 500;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: 1px solid $thickColor !important;
}

.ant-tabs-content-holder {
    margin: 0 10px;
    padding: 0 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 180px);
}

.ant-tabs-ink-bar {
    background: $primaryColor;
    border-radius: 50px 50px 0 0
}

.ant-tabs-tab:hover {
    color: $primaryColor;
}

.ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
    border-radius: 20px 20px 0 0;
}

/* ======= Tab styles end ======= */



/* ======= Card styles ======= */
.MuiGrid-spacing-xs-1>.MuiGrid-item {
    padding: 5px;
}

.MuiGrid-container {
    padding: 15px;
}

.MuiPaper-elevation1 {
    border-radius: 10px !important;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 7%) !important;
    border: 1px solid $thickColor;
}

.MuiGrid-item {
    @media(max-width: 639px) {
        width: 100%;
    }

    @media(min-width: 640px) {
        width: 50%;
    }

    @media(min-width: 896px) {
        width: 33.33%;
    }

    @media(min-width: 1200px) {
        width: 25%;
    }
}

.simplebar-track.simplebar-horizontal {
    display: none;
}

.cardProfileAvatar {
    height: 100px;
    width: 100px;
    min-width: 100px;
    min-height: 100px;
    margin: 15px auto;
    position: relative;

    .cardBlankAvatarImg {
        height: 100px;
        width: 100px;
        min-width: 100px;
        min-height: 100px;
        border-radius: 20%;
        background-position: 50%;
    }
}

.competitor {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    outline: unset;
    text-indent: -99999px;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 20%);
    position: absolute;
    right: -22px;
    bottom: -7px;
    background-position: 50% !important;

    img {
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 7px;
    }
}

.platinumMedal {
    background: $whiteColor url(../../Assets/images/platinum-medal.png) no-repeat;
}

.goldMedal {
    background: $whiteColor url(../../Assets/images/gold-medal.png) no-repeat;
}

.bronzeMedal {
    background: $whiteColor url(../../Assets/images/bronze-medal.png) no-repeat;
}

.silverMedal {
    background: $whiteColor url(../../Assets/images/silver-medal.png) no-repeat;
}

.MuiCardContent-root {
    padding: 40px 15px;
    text-align: center;
}

.card .MuiCardContent-root:last-child {
    padding-bottom: 40px;
}

.card .MuiGrid-root {
    display: flex;
    flex-flow: column;
}

.cardProfileName {
    font-weight: 600;
    font-size: 18px;
}

.cardProfileExperience,
.cardAadharNum {
    color: $darkGrayColor;
}

/* ======= Card styles end ======= */

/* ======= Select Status Option styles ======= */
.contentBody {
    .selectStatus {
        // width: 130px;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        border: unset;
        border-radius: 7px;
        padding: 5px 5px;
        cursor: pointer;

        & option {
            background: #fff !important;
            color: #000;
        }

        &.Draft {
            background: #d3d5d8;
            color: #373d42;
        }

        &.Processing {
            background: #bbebf2;
            color: #007281;
        }

        &.Pending {
            background: #efe0bd;
            color: #ab7400;
        }

        &.Rejected {
            background: #f7c9cb;
            color: #e42a33;
        }

        &.Approved {
            background: #c4eed3;
            color: #00a43b;
        }

        &.Active {
            background: #55b92b;
            color: $whiteColor;
        }

        &.Inactive {
            background: #747474;
            color: $whiteColor;
        }

        &.Deleted {
            background: #ff5555;
            color: $whiteColor;
        }
    }
}

/* ======= Select Status Option styles ======= */


/* ======= Pagination styles ======= */
.rc-pagination-item,
.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
    border-radius: 5px;
}

.rc-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    border-top: 1px solid $thickColor;
}

.rc-pagination-item-active {
    border-color: $primaryColor;
    background: $primaryColor;
    color: $whiteColor;
}

.rc-pagination-item-active:focus,
.rc-pagination-item-active:hover {
    border-color: $primaryColor;
}

.rc-pagination-item-active:focus a,
.rc-pagination-item-active:hover a {
    color: $whiteColor;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
    border-color: $primaryColor;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a,
.rc-pagination-prev:hover .rc-pagination-item-link {
    color: $blackColor;
}

.rc-pagination-prev button:after {
    content: '';
}

.rc-pagination-next button:after {
    content: '';
}

.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link {
    border-color: $primaryColor;
}

.rc-pagination-prev,
.rc-pagination-next {
    button {
        border: 1px solid #d9d9d9;
        height: 28px;
        padding: 0 10px;
        border-radius: 5px;
    }
}

.rc-pagination-prev.rc-pagination-disabled,
.rc-pagination-next.rc-pagination-disabled {
    button {
        cursor: not-allowed;
    }

    :hover {
        border-color: #d9d9d9;
    }
}

.rc-pagination-prev:hover button,
.rc-pagination-next:hover button {
    border-color: $primaryColor;
}

.rc-pagination-options {
    margin-left: 0;
}

/* ======= Pagination styles end ======= */

/* ======= MultiSelect styles ======= */
.MultiSelect-field {
    .css-6j8wv5-Input {
        padding-bottom: 0;
        padding-top: 0;
        margin: 0;
    }

    .css-1rhbuit-multiValue {
        border-radius: 5px;
        margin-right: 5px;
    }

    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border-radius: 7px;
    }

    .css-1pahdxg-control {
        box-shadow: unset;
    }

    // .css-1pahdxg-control:hover {
    //     border-color: $grayColor;
    // }

    // .css-1pahdxg-control:hover {
    //     border-color: $grayColor  !important;
    // }
    .css-1pahdxg-control {
        border-color: #ffc169 !important;
    }
}

/* ======= MultiSelect styles end ======= */


/* ======= List view flexgrow styles ======= */
.tableRow {
    .userEmailId {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
        font-weight: 400 !important;
        color: #aaa !important;
        font-size: 13px;
        text-transform: none !important;
    }

    .status,
    .source {
        align-items: center;
    }

    .rightArrow {
        flex-grow: 0.1 !important;
        flex-basis: 15px !important;
    }

    .sNo {
        flex-grow: 0.2 !important;
        flex-basis: 40px !important;
        min-width: 40px;
    }

    .userName,
    .assigned,
    .sInterview {
        flex-grow: 0.7 !important;
        flex-basis: 150px !important;
        min-width: 150px;
    }

    .mobile {
        flex-grow: 0.2 !important;
        flex-basis: 110px !important;
        min-width: 110px;
    }

    .aadhaar {
        flex-grow: 0.2 !important;
        flex-basis: 100px !important;
        min-width: 100px;
    }

    .panCard {
        flex-grow: 0.2 !important;
        flex-basis: 80px !important;
        min-width: 80px;
    }

    .city {
        // flex-grow: 0.2 !important;
        // flex-basis: 110px !important;
        text-align: center;
    }

    .status {
        // flex-grow: 0.2 !important;
        // flex-basis: 100px !important;
        text-align: center;
    }

    .source {
        // flex-grow: 0.2 !important;
        // flex-basis: 50px !important;
    }

    .startDate,
    .dueDate {
        flex-grow: 0.2 !important;
        flex-basis: 90px !important;
        min-width: 90px;
    }

    .services,
    .address,
    .designation,
    .submitted {
        flex-grow: 0.3 !important;
        flex-basis: 150px !important;
        min-width: 150px;
    }

    .msg {
        flex-grow: 0.7 !important;
        flex-basis: 150px !important;
        min-width: 150px;
    }

    .comments {
        flex-grow: 0.7 !important;
        flex-basis: 140px !important;
        min-width: 140px;
    }

    .tastTitle {
        flex-grow: 0.7 !important;
        flex-basis: 150px !important;
        min-width: 150px;
    }

    .space {
        flex-grow: 0.2 !important;
        flex-basis: 100px !important;
        min-width: 100px;
    }

    .projectName {
        flex-grow: 0.6 !important;
        flex-basis: 120px !important;
        min-width: 120px;
    }

    .services span,
    .comments span,
    .tastTitle span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
    }

    .installmentNum {
        flex-grow: 0.2 !important;
        flex-basis: 90px !important;
        min-width: 90px;
    }

}

/* ======= List view flexgrow styles end ======= */


/* ======= My Appointments styles  ======= */
.appointmentsWrapper {
    display: grid;
    grid-template-columns: 512px 1fr;

    .leftBlock {
        display: flex;
        background: $whiteColor;
        border-right: 1px solid $thickColor;
    }

    .rmdp-calendar {
        width: 422px;
        overflow: hidden auto;
        height: calc(100vh - 122px) !important;
    }

    .rmdp-wrapper {
        z-index: 1 !important;
        box-shadow: unset;
        border-radius: 0;
        border-right: 1px solid $thickColor;
    }

    .rmdp-day-picker {
        display: unset !important;

        >div {
            padding: 15px;
            margin: unset !important;
        }
    }

    .rmdp-arrow-container:hover {
        background-color: $primaryColor;
        box-shadow: unset;
    }

    .rmdp-day span {
        border-radius: 30%;
    }

    .rmdp-day.rmdp-selected span:not(.highlight) {
        background-color: #ff9101;
        box-shadow: unset;
    }

    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
        background-color: $bgColor;
        color: $blackColor;
    }

    .rmdp-day.rmdp-today span {
        background-color: #ff98008c;
    }

    .rmdp-day,
    .rmdp-week-day {
        width: 40px;
        height: 40px;
        margin: 5px 0;
    }

    .appointmentAvatars {
        padding: 20px 15px;
    }

    .ant-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
        box-shadow: rgb(0 0 0 / 17%) 3px 4px 10px 0px;
    }

    .todayDate {
        font-size: 16px;
        font-weight: 600;
        padding-left: 10px;
    }

    .avatarItems {
        display: flex;
        align-items: center;
        padding: 20px 10px;
        border-bottom: 1px solid #eee;
    }

    .appointmentList {
        overflow: hidden auto;
        height: calc(100vh - 285px) !important;
    }

    .ant-avatar-string {
        text-transform: uppercase;
    }

    @mixin cardAvatar {
        span.ant-avatar:nth-child(7n + 1) {
            background: #e68f00;
        }

        span.ant-avatar:nth-child(7n + 2) {
            background: #6352e9;
        }

        span.ant-avatar:nth-child(7n + 3) {
            background: #ff5bf5;
        }

        span.ant-avatar:nth-child(7n + 4) {
            background: #31bcc8;
        }

        span.ant-avatar:nth-child(7n + 5) {
            background: #cd4444;
        }

        span.ant-avatar:nth-child(7n + 6) {
            background: #1d9ce6;
        }

        span.ant-avatar:nth-child(7n + 7) {
            background: #4968a9;
        }
    }

    .ant-avatar-group {
        @include cardAvatar;
    }

    .appointmentList .appointmentCard:nth-child(7n + 1) {
        span.ant-avatar {
            background: #e68f00;
        }
    }

    .appointmentList .appointmentCard:nth-child(7n + 2) {
        span.ant-avatar {
            background: #6352e9;
        }
    }

    .appointmentList .appointmentCard:nth-child(7n + 3) {
        span.ant-avatar {
            background: #ff5bf5;
        }
    }

    .appointmentList .appointmentCard:nth-child(7n + 4) {
        span.ant-avatar {
            background: #31bcc8;
        }
    }

    .appointmentList .appointmentCard:nth-child(7n + 5) {
        span.ant-avatar {
            background: #cd4444;
        }
    }

    .appointmentList .appointmentCard:nth-child(7n + 6) {
        span.ant-avatar {
            background: #1d9ce6;
        }
    }

    .appointmentList .appointmentCard:nth-child(7n + 7) {
        span.ant-avatar {
            background: #4968a9;
        }
    }


    .rmdp-week-day {
        color: #777;
    }

    .rmdp-day span {
        font-weight: 500;
    }

    .rightBlock {
        background: $whiteColor;
    }

    .appointmentCard {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 20px;
        padding: 0 10px;

        span {
            font-weight: 500;
        }

        >span:first-child,
        .date-time {
            width: 108px;
            text-align: right;
            padding-right: 10px;
            color: $darkColor;
        }
    }

    .leadNameTitle {
        span:first-child {
            font-size: 15px;
            font-weight: 700;
            text-transform: capitalize;
        }

        span:last-child {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .apCardDetails {
        display: grid;
        grid-template-columns: 50px 1fr 100px;
        grid-gap: 10px;
        align-items: center;
    }

    .appointmentStatus {
        display: flex;
        justify-content: center;
    }

    .scheduleCallBtn.btn.Schedule.Call,
    .scheduleCallBtn.btn.Schedule.Meeting {
        display: inline-block;
        margin-top: 10px;
    }

    .btn.rescheduleBtn {
        display: unset !important;
    }

    .appointmentList {
        .noDataFoundBox1 {
            width: calc(100% - 45%);
        }
    }

    .cardItem {
        width: calc(100% - 10%);
        height: 100px;
        border-radius: 15px;
        box-shadow: 0px 7px 7px #80808017;

        .ant-card-body {
            padding: 25px 24px;
        }
    }

    .ant-avatar-group {
        margin-right: 10px;
    }

    @mixin cardItemStatus() {
        content: "";
        position: absolute;
        width: 12px;
        height: 100px;
        overflow: hidden;
        border-radius: 15px 0px 0px 15px;
    }

    .cardItem.Cold:before {
        @include cardItemStatus;
        border-left: 3px solid $cold;
    }

    .cardItem.Hot:before {
        @include cardItemStatus;
        border-left: 3px solid $hot;
    }

    .cardItem.Future:before {
        @include cardItemStatus;
        border-left: 3px solid $future;
    }

    .cardItem.Discarded:before {
        @include cardItemStatus;
        border-left: 3px solid $discarded;
    }

    .cardItem.Lost:before {
        @include cardItemStatus;
        border-left: 3px solid $lost;
    }

    .cardItem.Won:before {
        @include cardItemStatus;
        border-left: 3px solid $won;
    }

    .cardItem.Draft:before {
        @include cardItemStatus;
        border-left: 3px solid $Draft;
    }

    .cardItem.Processing:before {
        @include cardItemStatus;
        border-left: 3px solid $Processing;
    }

    .cardItem.Pending:before {
        @include cardItemStatus;
        border-left: 3px solid $Pending;
    }

    .cardItem.Rejected:before {
        @include cardItemStatus;
        border-left: 3px solid $Rejected;
    }

    .cardItem.Approved:before {
        @include cardItemStatus;
        border-left: 3px solid $Approved;
    }
}

.threeDots {
    margin-left: 5px;
    border-radius: 10px;
    height: 40px;
}

.rmdp-arrow {
    margin-top: 6px;
    padding: 3px;
}


.verticalCalendar.rdrDateRangePickerWrapper {
    position: unset;
    box-shadow: none;

    .rdrDay {
        height: 50px !important;
    }

    .rdrDefinedRangesWrapper {
        width: 145px;
    }

    .rdrStaticRangeLabel {
        text-align: center;
    }

    .rdrInputRanges {
        display: none;
    }

    .rdrMonthAndYearWrapper {
        border-bottom: 1px solid $bgColor;
    }

    .rdrMonths {
        flex-direction: column;
        overflow-y: scroll;
        height: calc(100vh - 220px);
    }
}

.rdrDateDisplay {
    color: $primaryColor !important;
}

.rdrMonthName {
    color: $blackColor !important;
}

.dashboardCard.appointmentsWrapper {
    display: block;
    grid-template-columns: unset;

    .rightBlock {
        background: unset;
    }

    .appointmentList {
        height: calc(100vh - 61px) !important;
    }
}

.rdrMonthPicker {
    select {
        width: 100px;
    }
}

.rdrYearPicker {
    select {
        width: 80px;
    }
}

.rdrMonthAndYearPickers select:hover {
    background-color: rgb(244 245 249) !important;
}

/* ======= My Appointments styles end ======= */


/* ======= Custom Popover styles ======= */
.customPopover {
    width: 360px;

    .ant-popover-title {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        padding: 15px 0;
        border-bottom: 1px solid $thickColor;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 7px;
        border: unset;
    }

    .ant-select-multiple .ant-select-selector {
        padding: 6px;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: red;
        box-shadow: unset;
    }

    .advanceFilterWrapper .btn-warning {
        color: $whiteColor;
        height: 40px;
        padding: 0.375rem 0.75rem;
        border-radius: 7px;
    }

    .advanceFilterWrapper .btn-link {
        margin-right: 20px;
    }

    .ant-popover-inner {
        border-radius: 10px;
        box-shadow: 0px 12px 20px 5px rgb(0 0 0 / 7%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .ant-popover-inner-content{
        width: 100% !important;
    }
    .footerBtns {
        padding: 10px 0;
        border-top: 1px solid $thickColor;
        display: flex;
        justify-content: flex-end;

        .btn {
            padding: 0 20px;
        }
    }
}

.btn-link {
    height: 40px;
    font-size: 13px;
    font-weight: 500;
    color: #6576ff;
    background: $bgColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-decoration: none;
    margin-right: 10px;
}

.anticon.anticon-close {
    color: $danger;
}

.ant-select-selection-overflow-item .ant-select-selection-item {
    background: $bgColor !important;
}

/* ======= Custom Popover styles end ======= */

/* ======= CRM Dashboard styles ======= */
.bodyHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 5px 30px;

    .selectBtn {
        border-radius: 7px;
        border: 1px solid $grayColor;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        height: 40px;
        margin-right: 15px;
        padding: 5px;
    }
}

.calendar {
    margin: unset;
}

.filterBtn {
    height: 40px;
    border: 1px solid #ddd;
    font-size: 13px;
    font-weight: 500;
    border-radius: 7px;
    background: #fff;
    padding: 5px 15px;
    margin-left: 10px;
}

.rdrDateRangePickerWrapper {
    position: absolute;
    top: 0px;
    right: -26px;
    box-shadow: 0px 10px 20px 0 rgb(0 0 0 / 20%);
    z-index: 9;
}

.rdrEndEdge,
.rdrDay,
.rdrDay.rdrDayToday,
.rdrInRange {
    color: #704505 !important;
}

.rdrStartEdge,
.rdrEndEdge {
    color: $primaryColor !important;
}

.rdrDayToday .rdrDayNumber span:after {
    background: $primaryColor !important;
}

.rdrStaticRangeSelected {
    color: $primaryColor !important;
}

.rdrDayHovered .rdrDayEndPreview,
.rdrDayHovered .rdrStartEdge,
.rdrDayHovered .rdrDayStartPreview,
.rdrDayHovered .rdrDayEndPreview,
.rdrDayHovered .rdrDayInPreview {
    border-color: $primaryColor !important;
}


.cardsLayout {
    .card {
        border-radius: 10px;
        box-shadow: 0 2px 15px -3px rgb(0 0 0 / 7%), 0 10px 20px -2px rgb(0 0 0 / 4%);
        border: 1px solid $thickColor;
    }

    .crdItem {
        display: grid;
        grid-template-columns: 1fr 100px;
        padding: 15px;
    }

    .cardIcon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .crdItem .iconCirclBg {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .smIcon {
            width: 30px;
            height: 30px;
        }
    }

    div[class*=col-]:nth-child(1) .iconCirclBg {
        background: #ffe1ec;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: 0px;
        }
    }

    div[class*=col-]:nth-child(2) .iconCirclBg {
        background: #ccfee2;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: -212px;
        }
    }

    div[class*=col-]:nth-child(3) .iconCirclBg {
        background: #e0fbff;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: -30px;
        }
    }

    div[class*=col-]:nth-child(4) .iconCirclBg {
        background: #e4f9c8;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: -90px;
            background-position-x: 2px;
        }
    }

    div[class*=col-]:nth-child(5) .iconCirclBg {
        background: #dae4ff;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: -120px;
            background-position-x: 2px;
        }
    }

    div[class*=col-]:nth-child(6) .iconCirclBg {
        background: #eeeeee;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: -150px;
            background-position-x: 2px;
        }
    }

    div[class*=col-]:nth-child(7) .iconCirclBg {
        background: #fde4dc;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: -180px;
            background-position-x: 2px;
        }
    }

    div[class*=col-]:nth-child(8) .iconCirclBg {
        background: #c8f6e8;

        .smIcon {
            background: url(../../Assets/images/cardIcons.png) no-repeat;
            background-position-y: -60px;
        }
    }
}

.cardTitle {
    font-size: 16px;
    font-weight: 600;
    color: $darkColor;
}

.cardLeads {
    color: $blackColor;
    font-size: 37px;
    font-weight: 500;
}

.cardInfo {
    display: flex;
    flex-direction: column;
}

.cardFooter {
    display: flex;

    i {
        width: 17px;
        height: 16px;
    }

    .upArrow {
        background: url(../../Assets/images/arrows.png) no-repeat;
        background-position-y: 0;
    }

    .downArrow {
        background: url(../../Assets/images/arrows.png) no-repeat;
        background-position-y: -15px;
    }
}

.dashboardCard {
    background: $whiteColor;
    border-radius: 10px;
    margin-bottom: 15px;

    .title {
        font-size: 17px;
        font-weight: 500;
        padding: 15px 15px;
    }

    .funnel,
    .pieChart {
        display: flex;
        justify-content: center;
        justify-items: center;
        padding: 50px 20px;
    }

    .pieChart {
        width: 430px;
        margin: 0 auto;
    }
}

/* ======= CRM Dashboard styles end ======= */

/* ======= Radio buttons styles ======= */
.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: $primaryColor;
}

.ant-radio-inner::after {
    background-color: $primaryColor;
}

.ant-radio-input:focus+.ant-radio-inner {
    box-shadow: 0 0 0 3px #f7a32b21;
}

/* ======= Radio buttons styles end ======= */

/* ======= Ant select dropdown styles ======= */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: unset;
}

.ant-select {
    border: 1px solid $grayColor;
    padding: 4px 0px;
    border-radius: 7px;
}

.ant-select-open {
    border-color: #ffc169;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: unset;
    box-shadow: unset;
}

/* ======= Ant select dropdown styles end ======= */

/* ======= Ant Picker Date and Time styles ======= */
.ant-picker-panel-container {
    border-radius: 7px;

    .ant-picker-panel {
        border-radius: 7px;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $primaryColor;
    border-radius: 7px;
}

.ant-picker-today-btn,
.ant-picker-now-btn {
    color: $primaryColor;
    font-weight: 500;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #704410;
    color: $whiteColor;
    border-radius: 7px;
}

.ant-picker-footer {
    .ant-btn-primary {
        border-color: $primaryColor;
        background: $primaryColor;
        border-radius: 7px;
    }
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: $bgColor;
}

/* ======= Ant Picker Date and Time styles end ======= */

/* ======= Timeline Tabs styles ======= */
.timelineTabs {
    .ant-tabs-tab {
        padding: 0px 35px 15px 35px;
        height: 58px;
    }

    .ant-tabs-tab:nth-child(1) {
        padding: 0px 35px 15px 0px;
        height: 58px;
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: unset;
    }

    .ant-tabs-tab-btn {
        display: flex;
        justify-content: center;
    }

    .ant-tabs-tab-btn::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        bottom: 10px;
        background-color: $whiteColor;
        border: 2px solid $primaryColor;
        border-radius: 50%;
        z-index: 1;
    }

    .ant-tabs-tab-disabled {
        .ant-tabs-tab-btn::before {
            background-color: #eee;
            border: 2px solid #eee;
        }
    }

    .ant-tabs-tab-btn::after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 17px;
        border-bottom: 2px solid $thickColor;
    }

    .ant-tabs-tab:nth-child(1) {
        .ant-tabs-tab-btn::after {
            width: 0%;
        }
    }

    .ant-tabs-tab:nth-child(2) {
        .ant-tabs-tab-btn::after {
            width: 203%;
        }
    }

    .ant-tabs-tab:nth-child(3) {
        .ant-tabs-tab-btn::after {
            width: 207%;
        }
    }

    .ant-tabs-tab:nth-child(4) {
        .ant-tabs-tab-btn::after {
            width: 0%;
        }
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        display: none;
    }

    .ant-tabs-tab-active {
        .ant-tabs-tab-btn::before {
            width: 17px;
            height: 17px;
            bottom: 9px;
            background-color: $primaryColor;
        }
    }

    .ant-tabs-tab {
        cursor: default;
    }
}

.timelineTabs.customers {
    .ant-tabs-tab:nth-child(2) {
        .ant-tabs-tab-btn::after {
            width: 221%;
        }
    }

    .ant-tabs-tab:nth-child(3) {
        .ant-tabs-tab-btn::after {
            width: 0%;
        }
    }
}

.TimeLineContainer {
    .dashboardCard {
        canvas {
            width: 100% !important;
            padding: 20px;
        }
    }
}

.dashboardCard {
    canvas {
        width: 100% !important;
        padding: 20px;
    }
}

/* ======= Dashboard card styles ======= */
.dashboardWrapper {
    margin: 20px;

    .smCard {
        border-radius: 7px;
    }

    .col:nth-child(1) .smCard {
        background: linear-gradient(130deg, rgba(79, 113, 254, 1) 0%, rgba(201, 122, 253, 1) 100%);
        color: #fff;
    }

    .col:nth-child(2) .smCard {
        background: linear-gradient(130deg, rgba(61, 175, 246, 1) 0%, rgba(117, 123, 253, 1) 100%);
        color: #fff;
    }

    .col:nth-child(3) .smCard {
        background: linear-gradient(130deg, rgba(234, 100, 129, 1) 0%, rgba(238, 161, 91, 1) 100%);
        color: #fff;
    }

    .col:nth-child(4) .smCard {
        background: linear-gradient(130deg, rgb(215, 71, 126) 0%, rgb(199 120 183) 100%);
        color: #fff;
    }

    .cardItem {
        padding: 20px 10px;

        .cardHead {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            border-bottom: 1px solid #ddc8fc;
            padding-bottom: 10px;
        }

        .cardTopTitle {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
        }

        .leadValue {
            font-weight: 500;
            font-size: 50px;
            line-height: 1.1;
        }

        .cardStatusInfo {
            display: flex;
            justify-content: space-around;
            text-align: center;

            div {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-top: 10px;

                span:first-child {
                    font-size: 20px;
                    font-weight: 500;
                    height: 34px;
                }
            }
        }
    }
}

/* ======= Dashboard card styles end ======= */

/* ======= ProjectDetails Dashboard styles ======= */
.projectDetails {

    // padding: 20px;
    .constructionInfo {
        padding: 0 10px 0 0;
    }

    .customStepsWrapper {
        display: flex;
        width: 100%;
        align-items: baseline;
    }

    .prescheduleInfo,
    .postScheduleInfo {
        h4 {
            margin-bottom: 60px;

            span {
                color: $darkColor;
            }
        }
    }

    .scheduleInfo {
        padding: 50px 10px;
        border-radius: 15px;
        border: 1px solid $thickColor;

        .ant-timeline-item-content {
            margin: 0 0 0 0px;
        }

        .ant-timeline-item-head {
            display: none;
        }
    }

    .prescheduleInfo {
        padding: 50px 10px 70px 10px;
        text-align: center;
        border-bottom: 1px solid $thickColor;

        .projectDate {
            width: 90px;
            height: 38px;
            display: flex;
            color: #f95463;
            border-radius: 10px;
            border: 1px solid;

            span:first-child {
                font-size: 30px;
                font-weight: 500;
                line-height: 1.2;
                border-right: 1px solid;
                width: 45px;
            }

            span:last-child {
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                font-size: 10px;
                font-weight: 600;
                width: 40px;
            }
        }

        .ant-steps-item-finish .ant-steps-item-icon,
        .ant-steps-item-process .ant-steps-item-icon {
            border-color: #f95463;
        }

        .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
            background: #f95463;
        }

        .ant-slider-track {
            background-color: #f95463 !important;
        }

        .ant-slider-handle {
            border: solid 2px #f95463;
        }

        .ant-slider-handle:focus {
            box-shadow: 0 0 0 5px rgb(247 83 100 / 17%);
        }
    }

    .postScheduleInfo {
        padding: 50px 10px 50px 10px;
        text-align: center;

        .projectDate {
            height: 38px;
            display: flex;
            color: #729729;
            border-radius: 10px;
            border: 1px solid;

            span:first-child {
                font-size: 32px;
                font-weight: 500;
                line-height: 1.2;
                border-right: 1px solid;
                width: 45px;
            }

            span:last-child {
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                font-size: 11px;
                font-weight: 600;
                width: 40px;
            }
        }

        .ant-steps-item-finish .ant-steps-item-icon,
        .ant-steps-item-process .ant-steps-item-icon {
            border-color: #729729;
        }

        .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
            background: #729729;
        }

        .ant-slider-track {
            background-color: #729729 !important;
        }

        .ant-slider-handle {
            border: solid 2px #729729;
        }

        .ant-slider-handle:focus {
            box-shadow: 0 0 0 5px rgb(115 150 53 / 17%);
        }

        .stepWrapper {
            top: 8px;
        }
    }

    .stepWrapper {
        width: calc(100% - 100px);
        text-align: center;
        position: relative;
        top: 10px;

        .ant-steps {
            position: relative;
            top: -5px;
        }

        span {
            position: relative;
            color: $darkGrayColor;
            display: flex;
            white-space: nowrap;
            justify-content: center;
            margin: auto;

        }

        .ant-slider {
            margin: unset;
        }
    }


    .ant-steps-item-icon {
        width: 12px;
        height: 12px;
    }

    .ant-steps-item-icon .ant-steps-icon {
        display: none;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        padding-left: 0px;
    }

    .ant-steps-vertical {
        flex-direction: row;
    }
}

.projectImagesBlock {
    position: relative;
    padding: 7px;
    background: #00000008;
    border-radius: 15px;

    .mainImage {
        img {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            cursor: pointer;
        }
    }

    .thumbnailImages {
        padding: 10px 10px 0;

        .col {
            height: 100px;
            padding-right: 10px;
            padding-left: 0;
        }

        .col:last-child {
            padding-right: 0;
            position: relative;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            cursor: pointer;
        }

        .lastImage {
            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.55);
                z-index: 2;
                border-radius: 15px;
            }

            .moreContent {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 3;
                font-size: 15px;
                font-weight: 500;
                color: $whiteColor;
                cursor: pointer;
            }
        }
    }

    .mainImage .bigDummyImage {
        background: url(../../Assets/images/big-img.jpg) no-repeat;
        height: 500px;
        display: block;
        border: 1px solid #eee;
        text-indent: -99999px;
        cursor: default;
    }

    .thumbnailImages .smlDummyImage {
        background: url(../../Assets/images/small-img.jpg) no-repeat;
        height: 100px;
        display: block;
        border: 1px solid #eee;
        text-indent: -99999px;
        cursor: default;
    }
}

.customModal.imgCarousel .ant-modal-body {
    padding: 20px 50px 50px 50px;
}
.slick-initialized .slick-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}
.slick-slide img{
    width: 100%;
}
.slick-track{
    display: flex;
    justify-content: center;
    align-items: center;
}

.customModal.imgCarousel {
    max-width: calc(100vw - 580px) !important;
    height: calc(100vh - 100px);
}

.slick-dots li {
    width: unset;
    margin: unset;
}

.slick-dots li button {
    width: unset;
}

.milestoneBlock {
    .ant-timeline-item-content {
        margin: 0 0 0 0;
    }
}

.ongoingMilestone {
    margin-top: 30px;
}

.ongoingMilestone,
.upcomingMilestone {
    h4 {
        padding-left: 10px;
    }
}

/* ======= ProjectDetails Dashboard styles end ======= */


/*  ===============  UI Developer  ===============   */

/* ======= payment reports  styles start ======= */

.PaymentCradsContainer {
    margin: 30px;

    .paymentCardItem {
        border: 1px solid;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 80px 1fr;
        margin-right: 20px;
        background-color: #00a43b;
        padding: 20px 10px 20px 10px;
        box-shadow: 1px 1px 1px 1px #F4F5F9;

        .paymentIcon {
            border: 4px solid;
            border-radius: 50%;
            padding: 10px;
            margin: 15px 0px 15px 10px;
        }

        .payments {
            padding: 10px;
            display: flex;
            flex-direction: column;

            .text {
                font-size: 17px;
                font-weight: 500;
            }

            .payment {
                font-size: 20px;
                font-weight: bold;
            }

            .paymentPercentage {
                border: 0px solid;
                border-radius: 10px;
                width: 60px;
                text-align: center;
            }

        }
    }

    .paymentCardItem:nth-child(1) {
        background-color: #FFFCF9;
        border-color: #FFFCF9;

        .paymentIcon {
            border-color: #F6DFBD;

        }

    }

    .paymentCardItem:nth-child(2) {
        background-color: #F4FDFA;
        border-color: #F4FDFA;

        .paymentIcon {
            border-color: #B7F0E7;
        }

        .payments {
            .paymentPercentage {
                background-color: #35AB91;
            }
        }
    }

    .paymentCardItem:nth-child(3) {
        background-color: #F7FAFF;
        border-color: #F7FAFF;

        .paymentIcon {
            border-color: #D1E3F9;
        }

        .payments {
            .paymentPercentage {
                background-color: #4DA6FF;
            }
        }
    }

    .paymentCardItem:nth-child(4) {
        background-color: #FEF8F6;
        border-color: #FEF8F6;

        .paymentIcon {
            border-color: #FBCDB7;
        }
    }

    .paymentCardItem:nth-child(5) {
        background-color: #FEF6FF;
        border-color: #FEF6FF;

        .paymentIcon {
            border-color: #F6C7F4;
        }
    }

}

//table styles
.tableContainer {
    table {
        border: 0px solid grey;
        width: 100%;

        thead {
            tr {
                border-bottom: 2px solid #eee;

                th {
                    padding: 10px;
                    color: #777777;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #eee;

                .index {
                    text-align: center;
                }

                td {
                    padding: 20px 0px 20px 9px;
                    color: #262727;

                    .requestBtn {
                        background-color: #00AFF0;
                        height: 40px;
                        font-size: 13px;
                        font-weight: 500;
                        color: #fff;
                        border: 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        padding: 0px 15px;
                    }

                    .resubmitBtn {
                        color: #fff;
                        font-size: 12px;
                        cursor: pointer;
                        padding: 5px 15px;
                        background-color: #1b87ec;
                    }
                }
            }
        }
    }
}

/* ======= payment reports  styles end ======= */


/* ======= Filter button styles ======= */
.filterWrapper {
    padding: 5px 10px;
    border-radius: 7px;
    border: 1px solid #eee;
    background: #fff;
    color: #777;
    height: 42px;
    display: flex;
    align-items: center;

    span:nth-child(1) {
        font-weight: 500;
    }

    span:nth-child(2) {
        margin-left: 10px;
        background: $bgColor;
        padding: 2px 8px;
        border-radius: 7px;
        color: #111;
        font-size: 14px;
        font-weight: 500;
    }
}

/* ======= Filter button styles end ======= */

/* ======= Filter tag styles ======= */
.tagsFilterData {
    display: flex;

    ul {
        display: flex;
        overflow-x: hidden;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: 5px;
        max-width: calc(70vw - 100px);
        width: calc(28vw - 100px);

        li {
            list-style: none;
            height: 42px;
            display: flex;
            align-items: center;
            background: $bgColor;
            margin-left: 10px;
            padding: 5px 15px;
            border-radius: 7px;

            span {
                max-width: 200px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: 500;
            }

            img {
                height: 15px;
                position: relative;
                left: 5px;
                cursor: pointer;

            }
        }

        li:first-child {
            margin-left: 0;
        }
    }

    button {
        background: #eee;
        border: 1px solid #eee;
        border-radius: 7px;
        color: #000;
        font-weight: 500;
        margin-left: 7px;
        min-width: 100px;
    }
}

.contentNavbar .arrow {
    height: 42px;
    padding: 2px 12px;
    border-radius: 7px;
    color: #000;
    background: $bgColor;
    margin-right: 0;
}

/* ======= Filter tag styles end ======= */
/* ======= calls and meetings tag styles ======*/
@mixin tags {
    display: inline-block;
    border-radius: 7px;
    text-align: center;
    padding: 5px 10px;
    font-size: 13px
}



.appointmentCard {
    .Scheduled {
        @include tags;
        background-color: #f5effc;
        color: #63538f;
    }

    .Completed {
        @include tags;
        background-color: #daf6e5;
        color: #0b6f46;
    }

    .Declined {
        @include tags;
        background-color: #edf1f6;
        color: #333333;
    }

    .Rescheduled {
        @include tags;
        background-color: #eee8d9;
        color: #926b5a;
    }

    .Upcoming {
        @include tags;
        background-color: #e2f1f9;
        color: #3881aa;
    }

    .Inprogress {
        @include tags;
        background-color: #faeaf5;
        color: #c87da5;

    }

    .Active {
        @include tags;
        background-color: #e6f4da;
        color: #527c30;
    }

    .Reassigned {
        @include tags;
        background-color: #f1effc;
        color: #7e67b7;
    }

}



/* ======= calls and meetings tag styles ======*/


/* ======= Project card styles ======*/
.vwCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    column-gap: 35px;
    row-gap: 20px;
}

.cardList {
    max-width: 280px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1em 1em 3em rgb(0 0 0 / 10%);
    background-color: #fff;
    margin-bottom: 15px;
    transition: transform 300ms ease;
    cursor: pointer;

    img {
        width: 280px;
        padding: 10px;
        border-radius: 20px;
    }

    .projectCardInfo {
        padding: 0 10px 10px;

        h4 {
            text-transform: capitalize;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .projectAllocatedBtn {
        display: flex;
        justify-content: center;
        padding: 10px 10px 10px 10px;
        margin: 0 10px;
        border-top: 1px solid #eee;
    }

    .projectLocation {
        font-size: 15px;
        color: #8c8b8e;
    }
    .projectCardOverflow{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }
    .projectPincode {
        font-size: 15px;
        color: #8c8b8e;
        margin-bottom: 10px;
    }
}

.cardList:hover {
    transform: scale(1.03);
}

.projectType {
    border-radius: 7px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 2px 7px;
    height: 25px;
}

.projectType.g1 {
    color: #9c1ab2;
    background: #f9effbc7;
    border: 1px solid #9c1ab2;
}

.projectType.g2 {
    color: #1292f5;
    background: #c7e4fb82;
    border: 1px solid #1292f5;
}

.projectType.g3 {
    color: #f69533;
    background: #fff1dc91;
    border: 1px solid #f69533;
}

.projectType.g4 {
    color: #344ad6;
    background: #344ad624;
    border: 1px solid #344ad6;
}

.projectType.g5 {
    color: #67812d;
    background: #f7ffe594;
    border: 1px solid #67812d;
}

.addNewProject.cardList {
    max-width: 230px;
    padding: 0 40px;
    display: flex;
    align-items: center;
}

.addNew {
    display: flex;
    flex-direction: column;
    text-align: center;

    span:first-child {
        font-size: 40px;
        color: #777777;
    }

    span:last-child {
        font-size: 20px;
        font-weight: 500;
        color: #777777;
    }
}

/* ======= Project card styles end ======*/

/* ======= Project card Detail styles ======*/
.editPencil {
    display: none;
}

.whContentBlock .projectDetails .contentBody {
    margin: 0px;
    padding: 10px;
    border: unset;
}

.projectImagesBlock:hover .editPencil,
.scheduleInfo:hover .editPencil {
    display: block;
    width: 10px;
    position: absolute;
    top: 7px;
    right: 7px;
    text-align: right;
    padding: 25px 10px 5px 35px;
    cursor: pointer;
    background: #0000003b;
    border-radius: 0px 15px 0px 5px;

    svg {
        position: absolute;
        left: 14px;
        top: 5px;
        fill: #ffffff;
        font-size: 17px;
    }
}

.scheduleInfo:hover .editPencil {
    top: -1px;
    right: -1px;
}

.projectNameInfo {
    .projName {
        display: flex;
        align-items: flex-start;
    }

    h3 {
        text-transform: capitalize;
        margin-bottom: 5px;
        margin-right: 10px;
    }

    .projDetlLocation,
    .projDetlCity,
    .projDetPincode {
        font-size: 15px;
        color: #8c8b8e;
    }
}

.projectPlotAreas {
    h6 {
        font-size: 12px;
    }

    .col h1 span {
        font-size: 1.6vw;
        font-weight: 500;
        margin-left: 5px;
    }
}

.text-right {
    text-align: right;
}

.totalAmount {
    flex-direction: unset !important;
    align-items: center;
    justify-content: flex-start !important;

    svg {
        font-size: 12px;
        margin-right: 3px;
    }

    span {
        font-size: 17px;
        color: #000;
        font-weight: 500;
    }
}

/* ======= Project card Detail styles end ======*/

/* ======= Payment Installment Edit hover styles ======*/
.paymentInstallment {
    .listItem.amount {
        .editAmount {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        .editPencil {
            svg {
                position: relative;
                left: 5px;
                top: -2px;
                fill: $primaryColor;
            }
        }
    }
}

.whAppTable .tableList .tableRow:hover {
    .editPencil {
        display: block;
    }
}

/* ======= Payment Installment Edit hover styles end ======*/

/* Rounded card Icons styles */
.allocatedDetails {
    padding: 10px;

    .ant-card {
        border-radius: 10px;
        box-shadow: 0 1px 12px 0px rgb(0 0 0 / 5%);
        margin-right: 5px;
    }

    .ant-card:last-child {
        margin-right: 0px;
    }
}

.roundedIcons {
    display: flex;
    align-items: center;
    gap: 10px;

    .blankAvatarImg {
        height: 60px;
        width: 60px;
        border-radius: 60px;
        border: 4px solid #0000000d;
    }

    h4 {
        margin-bottom: 2px;
    }

    span {
        font-size: 14px;
        color: #777;
    }
}

/* Rounded card Icons styles end */

/* BuildingProgress styles */
.buildingProgress {
    .col-sm-5 {
        padding-right: 0;
    }

    .col-sm-7 {
        padding-left: 0;
    }

    .mileStoneContainer {
        border-right: 1px solid $thickColor;
        background: $whiteColor;

        .headText {
            font-size: 16px;
            font-weight: 600;
        }

        .mileStoneCard {
            border: 1px solid $grayColor;
            border-radius: 10px;
        }
    }

    .mileStoneContainerHead {
        padding: 20px;
        border-bottom: 1px solid $thickColor;
    }

    .ant-timeline-item-content {
        top: 0;
    }

    .ant-timeline-item:first-child {
        padding-top: 20px;

        .ant-timeline-item-head {
            top: 46%;
        }
    }

    .ant-timeline {
        padding: 0 20px;
    }

    .ant-timeline-item-head {
        border: 2px solid #ddd !important;
        top: 37%;
        left: -3px;
    }

    .ant-timeline-item-tail {
        height: calc(100% - 0px);
    }

    .mileStoneCard:before,
    .mileStoneCard:after {
        right: 99.80%;
        top: 55%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .mileStoneCard:before {
        border-color: #bc000000;
        border-right-color: #ddd;
        border-width: 11px;
        margin-top: -13px;
    }

    .mileStoneCard:after {
        border-color: #ff000000;
        border-right-color: #ffffff;
        border-width: 9px;
        margin-top: -11px;
    }
}

.milestoneInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px 10px 20px;
    border-right: 1px solid #ddd;
    cursor: pointer;
    

    >div {
        display: flex;
        flex-direction: column;

        span:nth-child(1) {
            font-size: 14px;
            font-weight: 600;
        }

        span:nth-child(2) {
            font-size: 13px;
            color: $darkColor;
        }
    }

}

.milestoneNum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    border-right: 1px solid #ddd;

    >div {
        display: flex;
        flex-direction: column;
        text-align: center;

        span:nth-child(1) {
            font-size: 14px;
            font-weight: 600;
        }

        span:nth-child(2) {
            font-size: 33px;
            font-weight: 600;
        }
    }
}

.milestoneEtc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;

    >div {
        display: flex;
        flex-direction: column;

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }

    >div>.milestoneDate {
        display: flex;
        column-gap: 10px;
        text-align: center;

        >span {
            font-size: 33px;
        }

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            span:nth-child(1),
            span:nth-child(2) {
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

.projectMileStoneCard {
    border: 1px solid $grayColor;
    border-radius: 10px;
}

/* BuildingProgress styles end*/

.task-images {
    img {
        width: 150px;
        border-radius: 10px;
        border: 4px solid #00000012;
    }
}


/* Task Milestone styles */

.taskMilestone {
    margin: 25px;
    padding-right: 10px;
    height: calc(100vh - 288px);
    overflow-y: scroll;
    background-color: #fff;

    .ant-collapse>.ant-collapse-item {
        border-bottom: unset;
    }

    .ant-collapse {
        margin-bottom: 10px;
    }

    .ant-collapse {
        background-color: #ededed;
    }

    .ant-collapse-item:last-child>.ant-collapse-content {
        border-radius: 0 0 10px 10px;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 15px;
        border-radius: 0 0 10px 10px;
        border: 1px solid #eeee;
    }

    .taskDesc .btn-group {
        display: flex;
        justify-content: flex-end;
    }
}

.custmGroupBtns {
    display: flex;

    .btn {
        border-radius: 7px;
        margin-left: 7px;
    }
}

.fileContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 20px;

    .fileContent {
        display: flex;
        flex-direction: row-reverse;

        img {
            border-radius: 7px;
            border: 3px solid #00000014;
        }
    }

    .clearBtn {
        width: 14px;
        height: 14px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f44336;
        left: -8px;
        top: -4px;
        position: relative;
        z-index: 1;
    }

    button.clearBtn svg {
        width: 14px;
        fill: #f44336;
    }
}

/* Task Milestone styles end */

/* style for selected milestone */
.selected-milestone{
    border: 1px solid orange !important;  
}
/* logsmodal styles  start */
.logContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    .imgWrapper {
        width: 10%;
    }

    .contentWrapper {
        width: 90%;

        & div:nth-child(1) {
            display: flex;
            justify-content: space-between;
        }
    }

}

/* logsmodal styles  end */
/* Card dummy background image starts*/
@mixin smallDummyBackGround {
    background: url(../../Assets/images/small-img.jpg) no-repeat;
    height: 200px;
    display: block;
    border: 1px solid #eee;
    text-indent: -99999px;
    cursor: default;
    background-position: center

}

@mixin bigDummyBackGround {
    background: url(../../Assets/images/big-img.jpg) no-repeat;
    height: 500px;
    display: block;
    border: 1px solid #eee;
    text-indent: -99999px;
    cursor: default;
    background-position: center
}

.cardList .smlDummyImage {
    @include smallDummyBackGround;
    // @include bigDummyBackGround

}

/*Card dummy background image ends*/
/* images edit icons hover styles start */
.fileContent{
    position: relative;
}
 .fileContent:hover .editPencil{
    background: rgba(214, 205, 205, 0.231);
    border-radius: 5px 0px 10px 5px;
    display: block;
    position: absolute;
    bottom: 1px;
    right: 17px;
    cursor: pointer;
 }
/* images edit icons hover styles ends */
/* schedule modal loader changes*/
.loaderBox1 {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: calc(100vh - 115px);
    // width: calc(100vw - 80px);
}

/* schedule modal loader changes*/

/* plus icon with length  */
.plusIconWithLength{
 display: inline-block;
 cursor: pointer;
 font-size: 1.1rem;
 margin-left: 10px;
 color: rgb(245, 142, 142);
}
.plusIconWithLength:hover{
    color: rgb(218, 45, 45);
}
/* plus icon with length  */

/*card view evaluate btn */

.card{
    .card-data{
        .btn.evaluateBtn{
            background:#7da4fa;
        }
    }
}


.uploadBlock .ant-table-tbody > tr > td{
    padding: 16px 11px;
}