.head{
    display: flex;
    flex-direction: row;
}
.head-text{
    margin-top: 20px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
}
.open-arrow{
    font-size: 25px;
    margin-top: 10px;
    margin-left: 20px;

}
.admin-close-arrow{
    font-size:25px;
    margin-top: 10px;
    margin-left: 100px;
}
.wh{
    margin-left: 10px;
}

.wehouse{
    margin-left: 25px;
}
.horizantal-line{
    border: 1px solid white;
}
.icon.hover{
    color: white !important;
}
/* .item:hover {
    background-color: orange;
    border-radius: 10px;
} */

.menu-bar{
    position: relative;
    height:100vh;
}
 .toggle-btn-open{
     font-size: 25px;
     margin-top: 10px;
     margin-left:10px;
 }
 .toggle-btn-close{
    font-size: 25px;
 }

