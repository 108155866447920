

.contentBody{
    small{
        color: red !important;
    }
    .errorInput{
        border-color: red;
    }
    .errorInput::placeholder{
       color: rgb(250, 80, 80);
       font-weight: lighter;
    }
    .adrressCheckbox{
    span{
        color: #aaa;;
    }
}
}