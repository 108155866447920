
.users-layout{
    background-color:#e6e6e6 ;
    height:100%;
}
.users-head-label{
    display: flex;
    flex: 100%;
    height: 50px;
    width: 1600px;
    background-color:#f5f5f5;
}
.users-font{
    font-size: 18px;
    margin-top: 10px;
    margin-left: 20px;
    font-weight: bold;
}
.adduser-btn{
    display: flex;
    border: 0px solid ;
    border-radius: 5px;
    background-color: rgb(255, 234, 196);
    margin-left:85%;
    margin-top: 10px;
    height: 30px;
    width: 140px;
    color: orange;
}

.adduser-text{
    font-size: 15px;
    margin-top: 3px;
    margin-left: 5px;
}
/* .add-icon{
    margin-left:5px;
} */
