/* .pe-head{
    display: flex;
    flex-direction: row;
}
.pe-head-text{
    margin-top: 20px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
} */
/* .pe-wehouse{
    margin-left: 25px;
} */
/* .pe-close-arrow{
    margin-top: 10px;
    margin-left: 70%;
} */
/* .pe-open-arrow{
    margin-top:20px ;
    margin-left: 30px;
} */
/* .menu-icon{
    margin-left:18px ;
} */
/* .item:hover {
    background-color: orange;
    border-radius: 10px;
} */
/* sidebar routes  */
/* ant-design styles */
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after{
    background-color: none !important;
}

.ant-menu-inline .ant-menu-item::after{
    border-right: none !important;
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
    position: relative !important;
    top: 0px;
}
