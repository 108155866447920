.project-layout{
    background-color: #e6e6e6;
    height: 100%;
}
.project-head-data-cb{
margin-left: 60px;
}
.project-head-data-fn{
    margin-left: 60px;
    width: 200px;
    color:#979696;
    font-weight: bold;
}
.project-head-data-ct{
    width: 200px;
    color:#979696;
    font-weight: bold;
}
.project-head-data-p{
  width: 150px;
    color:#979696;
    font-weight: bold;
}
.project-head-data-d{
    width: 220px;
    color:#979696;
    font-weight: bold;
}
.project-head-data-f{
    width: 130px;
    color:#979696;
    font-weight: bold;
}
.project-head-data-s{
    width: 10vw ;
    font-size: 0.7vw;
    color:#979696;
    font-weight: bold;
}
.project-head-data-tc{
    width: 8vw ;
    font-size: 0.7vw;
    color:#979696;
    font-weight: bold;
}
.project-head-data-a{
    width: 4vw;
    font-size: 0.7vw;
    color:#979696;
    font-weight: bold;
}
.project-table-data{
    margin-left: 3vw;
    font-weight: bold;
}
.project-table-fN{
    margin-left: 60px;
    width: 200px;
    font-weight: bold;

}
.project-table-ct{
    width: 200px;
    font-weight: bold;

}
.project-table-p{
    font-weight: bold;
    width: 150px;

}
.project-table-d{
    width: 220px;
    font-weight: bold;
}
.project-table-f{
    width: 130px;
    font-weight: bold;

}
.project-table-s{
    width: 10vw ;
    font-weight: bold;

}
.project-table-tC{
    width: 8vw ;
    font-weight: bold;

}
.Caution-Deposite{
border: 0px solid ;
border-radius: 20px;
background-color: rgb(116, 223, 95);
width: 7vw;
color: white;
text-align: center;
}
.signed-contract{
    border: 0px solid ;
    border-radius: 20px;
    background-color: rgb(51, 192, 185);
    width: 7vw;
    color: white;
    text-align: center;
    }
/* .action-icons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 4vw;
} */
.trash-icon,.edit-icon{
    height: 20px;
}
.trash-icon{
    margin-left: 1vw;
}
