.notifications-container {
  position: absolute;
  top: 100%;
  left: -150%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  width: 381px;
  max-width: 90vw;
  max-height: 750px;
  padding: 16px;
  transition: width 0.3s ease;

}
.notifications-container.view-all-expanded {
  width: 500px; /* Increased width when view all is clicked */
  max-width: 90vw;
}


.notifications-list {
  max-height: 600px;
  overflow-y: auto;
}



.notifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.notifications-header h3 {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1E293B;
}

.mark-all {
  color: #007bff;
  cursor: pointer;
  white-space: nowrap;
}

.tabs {
  display: flex;
  gap: 15px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 8px;
}

.badge {
  background-color: #e0e0e0;
  color: #484141;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

.tab.active .badge {
  background-color: #FF9800;
  color: #fff;
}

.tab {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  color: #555;
  padding-bottom: 5px;
}

.tab.active {
  font-weight: bold;
  border-bottom: 2px solid #FF9800;
}

.notifications-list {
  max-height: 600px;
  overflow-y: auto;
}

.notification-item {
  padding: 17px;
  border-bottom: 1px solid #f0f0f0;
}

.content-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.text-content {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.house-image {
  width: 48px;
  height: 48px;
  border-radius: 200px;
  flex-shrink: 0;
}

.project-title {
  color: #2E70E8;
  font-weight: 600;
  font-size: 13px;
  margin: 0 0 4px 0;
  font-family: 'Poppins';
  line-height: 18px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.description {
  font-family: 'Poppins';
  font-weight: 500;
  margin: 0;
  color: #334155;
  font-size: 13px;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}



.more-btn {
  font-size: 20px;
  cursor: pointer;
  color: #666;
  flex-shrink: 0;
  margin-left: auto;
  align-self: flex-start;
}

.view-all {
  text-align: end;
  margin-top: 16px;
}

.view-all a {
  display: inline-block;
  color: #2E70E8;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}
@media screen and (max-width: 1024px) { /* For laptops */
  .notifications-container {
    width: 90%;
    max-width: 400px;
  }

  .notifications-container.view-all-expanded {
    width: 90%;
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) { /* For tablets and smaller laptops */
  .notifications-container {
    width: 95%;
    max-width: 380px;
  }

  .notifications-container.view-all-expanded {
    width: 95%;
    max-width: 450px;
  }

  .view-all a {
    font-size: 12px; /* Adjust font size */
  }
}
.notification-item:hover {
  background-color: #FFFAEF;
}

.more-menu-container {
  position: relative;
  flex-shrink: 0;
}

.more-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 150px;
  z-index: 1000;
  margin-top: 5px;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  gap: 16px;
  white-space: nowrap;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.menu-item span {
  color: #1E293B;
  font-size: 14px;
  font-family: 'Poppins';
}

.checkmark-icon {
  color: #FF9800;
  font-size: 18px;
}

.no-notifications-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  gap: 10px;
}

.no-notification-img {
  width: 100px;  /* You can adjust the size of the image */
  height: auto;
}

.no-notification-text {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #334155;
  margin-top: 10px;
}
