.BulkBtn{
  padding: 6px;
  border: 1px solid ;
  border-radius: 5px;
}
.ApplyBtn{
    margin-left: 20px;
    padding:5px;
    border: 1px solid;
    border-radius: 5px;
}

/* Tkp Modal Styles */
.ProfileHandler{
  display: flex;
  align-items: center;
  margin-left: 30px;

}
/* .userName{
  font-weight: bold;
  font-size: 20px;
  margin-left: 100px;
} */
.userStatus{
  bottom: 70px;
  height: 31px;
  right: 20px;
  top: 75px;
  position: absolute;
   
}
.pm-approval-modal .ant-modal-content{
  border-radius: 10px;
  width: 800px;
  height: 950px;
}

.data-row{
  margin-left: 20px;
}

.ant-modal-header{
  border-radius: 10px;
}
.RejectBtn,.approveBtn{
  background-color: white;
  border-radius: 30px;
  height: 40px;
}
.approveBtn{
  border:1px solid green;
  color: green;
  margin-right: 20px;
}
.RejectBtn{
  border: 1px solid red;
  color: red;
  margin-right: 40px;
}
.onboarding-text{
  margin-right: 30px;
  margin-top: 30px;
}
.rejeced-text{
  margin-right: 30px;
  margin-top: 30px;
}
.link{
  font-size: 20px;
}
.text-area-reject{
  padding: 10px;
  border-radius: 5px;
}
.ant-btn:hover, .ant-btn:focus{
  color:none;
  background-color: none;
  color: none;
  border-color: none;
}
.onboard-text{
  font-size: 20px;
}
.rejectedBtn-Submit{
  background-color: orange;
  color: white;
  border-radius: 5px;
  height: 40px;
}