/* .addTaskform{
    background-color: yellowgreen;
    height: 30rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
} */
.addTaskform .formItem{
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
}
.dateWrapper{
    display: flex;
    column-gap: 2rem;
}
/* .addTaskform  .ant-upload-select-picture-card{
    width: 100%;
    border:2px dashed #d9d9d9;
} */
/* .addTaskform textarea{
    height: 5rem;
    border-radius: 7px;
} */

.addtaskSubmit{
    /* background-color: yellow; */
    text-align: right;
}

.upload-container {
    margin-top: 14px;
    cursor: pointer;
}
 
.upload-area {
    border: 1.8px dashed #E5E5E5;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: #FAFAFA;
    cursor: pointer;
}
 
.upload-instruction {
    margin: 10px 0;
}
 
.upload-link {
    color: orange;
    font-weight: bold;
    cursor: pointer;
}
.uploaded-images {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

.image-preview {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    gap: 10px;
}

.image-preview:hover .image-overlay {
    opacity: 1;
}

.icon-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
}

.view-button .fas,
.delete-button .fas {
    font-size: 1.5em;
}

.view-button:hover {
    color:orange
}

.delete-button:hover {
    color: orange
}
.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.preview-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    overflow: auto;
}

.preview-image {
    width: 100%;
    height: auto;
}

.close-button {
    position: absolute;
    top: -5px;
    right: 3px;
    font-size: 24px;
    color: red;
    cursor: pointer;
}