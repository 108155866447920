
/* ======= Small Devices styles ======= */

@media only screen and (max-width: 600px) {
    .whSigninPanel{
        width: unset;
        position: unset;
        left:unset;
    }
    .whSiginContainer{
        padding: 20px 30px;
    }
}


@media only screen and (max-width: 700px) {
    .whContentBlock {
        .contentNavbar{
            flex-flow: wrap;
            .groupBtns {
                margin-top: 10px;
            }
        }
    }
    .searchField{
        width:200px;
        margin-right: 15px;
    }
    .tableList{
        .tableHead{
            display: none !important;
        }
        .tableRow{
            position: relative;
            flex-direction: column !important;
        }
        [data-head]:before{
            position: absolute;
            left: 20px;
            max-width: 170px;
            content: attr(data-head) " : ";
            text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            color: #777;
        }
        .listItem{
            margin-left: 170px !important;
            flex-basis: unset !important;
        }
        .rightArrow{
            align-self: flex-start;
        }
    }
    .whAppTable .tableList .alignItemsC {
        align-items: flex-start;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1366px){
    .row.cardsLayout>* {    
        width: 33%;
        max-width: 33%;
    }
    .appointmentsWrapper .appointmentList .noDataFoundBox1 {
        width: 42%;
    }
    .appointmentList .noDataFoundBox1{
        img{
            width: 250px;
        }
        span {
            font-size: 15px;
        }
    }
    .tagsFilterData ul{
        max-width: calc(20vw - 120px);
        width: calc(20vw - 120px);
    }
}