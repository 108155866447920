.projectdetails-wrapper{
    /* background-color: yellowgreen; */
    width: 300px;
    border-radius: 10px;
    padding: 1px;
}
.projectdetails-wrapper img{
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}
.projectdetails-wrapper .projectdetails{
    /* background-color: yellowgreen; */
    padding: 10px;
    border-bottom: 1px solid rgb(188, 184, 184);
}
.projectdetails-wrapper .buttonPadding {
    padding: 10px 80px;
}
