.user-main-layout{
    margin: 70px 0px 0px 60px;
    height: 80%;
}
.user-list-layout{
    border:  0px solid;
    height: 100%;
    width:  96%;
    background-color:#ffff;
    border-radius: 10px;

}
.user-list-layout-head{
    border: 0px solid;
    border-radius: 10px;
    width: 100%;
    background-color:#ffff;
    display: flex;
}

.search-users-field{
    width: 400px;
    border: none;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
}
.serach-user-layout{
    margin-left: 2%;
    margin-top:3vh;
}
.users-toggle-btn{
    border: 0px solid;
    width: 100px;
    background-color: #f2f2f2;
    margin-left:750px;
    margin-top:3vh;
    display: flex;
    border-radius: 5px;
}
.settings-icon,.setting-slider-icon{
    margin-left: 30px;
    margin-top: 30px;
}

.app-icon,.list-icon{
    position: relative;
    margin: auto;
}

.user-edit-icon,.user-delete-icon{
    height: 20px;
    margin-left: 10px;

}
.user-table-head{
    display: flex;
    margin: auto;
    height: 50px;
    align-items: center;
}
.user-table-data-row{
    display: flex;
    height: 100px;
    align-items: center;
    border:1px solid whitesmoke;
    border-top:1px solid whitesmoke ;
}
.user-head-data-cb{
   color:#979696;
   font-weight: bold;
   margin-left: 40px;
}
.user-head-data-n{
    margin-left: 60px;
    width:200px;
   color:#979696;
   font-weight: bold;

}
.user-head-data-mn{
   width:250px;
   color:#979696;
   font-weight: bold;

}
.user-head-data-d{
    width: 200px;
   color:#979696;
   font-weight: bold;

}
.user-head-data-ar{
    width: 300px;
   color:#979696;
   font-weight: bold;

}
.user-head-data-s{
   width: 250px;
   color:#979696;
   font-weight: bold;

}
.user-head-data-ac{
   width: 200px;
   color:#979696;
   font-weight: bold;

}

.user-table-data-cb{
    font-weight: bold;
    margin-left: 40px;
}
.user-table-data-n{
    margin-left: 60px;
    width: 200px;
    font-weight: bold;
}
.user-table-data-mn{
    width:250px;
    font-weight: bold;
}
.user-table-data-d{
    width: 200px;
    font-weight: bold;
}
.user-table-data-ar{
    width: 300px;
    font-weight: bold;
}
.user-table-data-s{
    width: 250px;
    font-weight: bold;
}
.user-table-data-ac{
    width: 200px;
}

/* .vertical-line{
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid rgb(218, 214, 214);
} */
.users-edit-icon,.users-trash-icon{
    height:2vh;
}
.users-edit-icon{
    margin-left: 7.6vw;
}

/* .right-angle-icon{
    height: 2vh;
    margin-left: 2vw;
} */

/* .no-data-layout{
    margin-top:25vh ;
    margin-left: 35vw;

} */
/* .no-data-found-text{
 margin-left: 4.5vw;
 margin-top: 1vh;
 font-size: 1.5vw;
 color: orange;
} */