.tkp-list-layout{
    border: 1px solid grey;
    border-radius: 10px;
    width: 94%;
    background-color: white;
  }
  .tkp-list-navbar{
      display: flex;
      padding: 10px;
  }
  /* .tkp-search-user-layout{
      margin-top: 10px;
      margin-left: 20px;
  } */
  .tkp-search-users-field{
      padding: 10px;
  }
  .tkp-toggle-btn{
      border: 0px solid;
      height: 6vh;
      width: 6vw;
      background-color: #f2f2f2;
      margin-left: 950px;
      display: flex;
      border-radius: 5px;
  }
  .tkp-setting-slider-icon,.tkp-settings-icon{
      height: 25px;
      margin-top: 20px;
      margin-left: 40px;
  }

  .pic{
      height: 50px;
      width: 50px;
      border: 0px solid ;
      border-radius: 5px;
      margin-right: 10px;
  }
  .table-head-row{
       height: 40px;
       border-bottom: 2px solid grey;
  }
  /* .tkp-head-data-p{
      width:250px ;
      margin-left: 6vw;
      font-size: 0.7vw;
      color:#979696;
      font-weight: bold;
  }
  .tkp-head-data-c{
      width:150px ;
      font-size: 0.7vw;
      color:#979696;
      font-weight: bold;
  }
  .tkp-head-data-a{
      width:150px ;
      font-size: 0.7vw;
      color:#979696;
      font-weight: bold;
  }
  .tkp-head-data-s{
      width: 300px ;
      font-size: 0.7vw;
      color:#979696;
      font-weight: bold;
  }
  .tkp-head-data-st{
      width: 200px ;
      font-size: 0.7vw;
      color:#979696;
      font-weight: bold;
  }
  .tkp-head-data-ab{
      width: 200px;
      font-size: 0.7vw;
      color:#979696;
      font-weight: bold;
  }
  .tkp-table-cb{
      margin-left: 50px;
      font-weight: bold;
  }
  .tkp-table-p{
      width:250px;
      margin-left: 2vw;
      font-weight: bold;
  
  }
  .tkp-table-c{
      width:150px ;
      font-weight: bold;
      color:#979696;
  
  
  }
  .tkp-table-a{
      width: 150px;
      font-weight: bold;
      color:#979696;
  
  
  }
  .tkp-table-s{
      width: 300px ;
      font-weight: bold;
      color:#979696;
  
  
  }
  .tkp-table-st{
      width: 200px ;
      font-weight: bold;
  
  }
  .tkp-table-ab{
      font-weight: bold;
      width: 200px;
      display: flex;
  
  }
  .tkp-table-dots{
      width: 100px;
      margin-left:70px;
  } */
  .Caution-Deposite{
  border: 0px solid ;
  border-radius: 20px;
  background-color: rgb(116, 223, 95);
  width: 7vw;
  color: white;
  text-align: center;
  }
  .signed-contract{
      border: 0px solid ;
      border-radius: 20px;
      background-color: rgb(51, 192, 185);
      width: 7vw;
      color: white;
      text-align: center;
      }
  /* .action-icons{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 4vw;
  } */
  .dots-icon{
      height: 20px;
  }
  .assigned-layout{
  display: flex;
  }
  /* .assigned-circle{
      border: 0px solid;
      background-color: rgb(241, 210, 151);
      border-radius: 50%;
      text-align: center;
      margin-right: 10px;
      height: 40px;
      width: 40px;
  } */