@keyframes toasteranimation{
    0%{
        opacity: 1;
        transform: translateY(-100px);

    }
    25%{
        opacity: 0.8;
        transform: translateY(-350px);
    }
    33%{
        opacity: 0.5;
        transform: translateY(-400px);
    }
    75%{
        opacity: 0.3;
        transform: translateY(-500px);
    }
    100%{
        opacity: 0;
        transform: translateY(-1050px);
    }
}
.Toastify__toast-container--bottom-right{
    animation-name: toasteranimation;
    animation-duration: 5s;
    animation-timing-function:linear;
}