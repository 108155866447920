.contentHead{
 height: 62px;
 display: flex;
 flex-direction: row;
}
.contentHead .rounded {
    padding: 5px 10px;
    background-color: rgb(255,255,255);
    border: 1px solid #eee ;
    margin-top: 20px;
    margin-left: 30px;
}
/* .date-selector{
    width: 250px;
    margin-top: 20px;
    background-color: rgb(255,255,255);
    border: 1px solid #eee ;
    margin-left: 895px;
    font-weight: bold;
} */
/* .Filter{
    padding: 8px;
    background-color: rgb(255,255,255);
    border: 1px solid #eee ;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 10px;
} */
.calendar{
    position: relative;
}


.rdrDefinedRangesWrapper{
width: 202px;
}
.rdrCalendarWrapper{
    font-size: 11px;
}
/* .crmCardsRow1,.crmCardsRow2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */




.LeadsLayout{
    display: flex;
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;
}



.headText{
    font-weight: bold;
    font-size: 18px;
    margin-left: 20px;
}
.headTitle{
    padding-top: 20px;
}

.chartHeadTitle{
  height: 40px;
  padding: 5px;
}
.chartHeadTitle span{
  font-weight: bold;
  font-size: 20px;
  padding-left: 20px;
}

.square{
    height: 10px;
    width: 10px;
}
