
.customer-details-navbar{
     display: flex;
     flex-direction: row;
     height: 50px;
     background-color:#bebdbd63;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;

}
.customers-head-text{
    font-weight: bold;
    font-size: 18px;
    margin-left: 20px;
    margin-top: 10px;
}
.customers-details-saveBtn{
    background-color: rgb(17, 139, 72);
    border: none;
    padding: 7px 30px;
    border-radius: 5px;
    margin-top: 7px;
    color: white;
    margin-left: 20px;
}
.project-details-saveBtn{
    background-color: rgb(17, 139, 72);
    border: none;
    padding: 7px 30px;
    border-radius: 5px;
    margin-top: 7px;
    color: white;
    margin-left: 20px;

}

.upload-container {
    margin-top: 14px;
    cursor: pointer;
}
 
.upload-area {
    border: 1.8px dashed #E5E5E5;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: #FAFAFA;
    cursor: pointer;
}
 
.upload-instruction {
    margin: 10px 0;
}
 
.upload-link {
    color: orange;
    font-weight: bold;
    cursor: pointer;
}
 
.file-types {
    color: #999;
    margin-top: 5px;
}
 
.upload-doc-icon {
    color: #A3A3A3;
    font-size: 26px;
    cursor: pointer;
}
 
.uploaded-files-list {
    margin-top: 20px;
}
 
.file-list {
    list-style-type: none;
    padding: 0;
}
 
.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
    background-color: #FFFFFF;
}
 
.file-details {
    display: flex;
    align-items: center;
}
 
.file-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #4285F4; /* Blue for docx */
}
 
.file-name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
 
.file-actions {
    display: flex;
}
 
.action-btn {
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 10px;
}
 
.action-btn i {
    font-size: 18px;
    color: #A3A3A3;
}
 
.action-btn i:hover {
    color: #FF4D4D;
}

.save-icon {
    color: #9E8DB4; 
    background-color: #F4F0F9;
    border-radius: 50%; 
    padding: 5px; 
    width: 26px; 
    height: 26px; 
   
}
.preview-icon {
    color: #4B6783; 
    background-color:#E5ECF3;
    border-radius: 50%; 
    padding: 5px; 
    width: 26px; 
    height: 26px; 
}
.delete-icon{
    color: #FF2F2F; 
    background-color:#FEF1F1;
    border-radius: 50%; 
    padding: 5px; 
    width: 26px; 
    height: 26px; 
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center; 
    width: 80%;
    max-width: 800px;
    height: 50%;
    overflow: hidden; 
}

iframe {
    width: 100%; 
    height: 100%; 
    border: none; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
}

.close-btn {
    color: grey;
    border-radius: 63%;
    padding: 5px;
    width: 30px;
    height: 30px;
    font-size: 12px;
}
.close-btn:hover {
    background: rgba(255, 255, 255, 0.8);
}
.spinner {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #FFA500; /* Orange */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle; /* Align with text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

