.genericbtns {
    display: flex;
    flex-wrap: wrap;

}

.genericbtns .btn {

    margin-right: 15px;

}
.genericfooter{
    margin: 25px 0 10px 0;
    display: flex;
    justify-content: flex-end;
}

.genericfooter .btn{
    margin-left: 10px;
}
.whCardMargin{
    margin-bottom: 20px;
}