.sw-details-activeBtn{
    background-color: rgb(26, 139, 83);
    border: 0px solid ;
    border-radius: 5px;
    color: white;
    padding:5px;
    margin-top: 8px;

} 
/* .full-form-group{
    margin-left: 20px;
} */
