.cards,.Act-tar,.incentives,.expenses{
    background-color: white;
    border: 0px solid white;
    border-radius: 10px;
    margin: auto;
}
.expenses1{
    background-color: white;
    border: 0px solid white;
    border-radius: 10px;
    /* margin: auto; */

}
.dashboard-layout{
    margin:30px;
    margin-top: 0px;
}
.cards-data{
    padding: 10px;
}
.cardHead{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #eee;
}
.cardContent{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top:10px;
}
.cardContent span{
    text-align: center;
}
.cardHead .HeadTitle{
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding-top: 5px;

}
.LeadsFont{
    font-weight: bold; 
    font-size: 40px;
    padding-left: 70px;
}
/* .over-view-layout{
    margin-top: -20px;
} */
/* .OverView,.incentives{
    height: 300px;
    width: 980px;
} */
/* .Act-tar,.expenses{
    height: 300px;
    width: 460px;
} */
.head-lables{
    color: black;
    font-size: 18px;
    margin-top: 5px;
    margin-left: 15px;
}
.p-box,.A-box,.R-box{
    height: 10px;
    width: 10px;
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 5px;
    border-radius: 2px;
}
.p-box{
    background-color:rgb(252, 194, 86);
}
.A-box{
    background-color: rgb(63, 231, 195);
}
.R-box{
    background-color:rgb(214, 84, 36) ;
}
.incentivesBorder{
    border: 1px solid whitesmoke;
    height: 400px;
}
.incentivesHead{
    border: 2px solid whitesmoke;
    height: 260px;
    margin-top: 20px;
    margin-left: -24px;
    margin-right: 20px;
    border-left: 0px;
}
.incent-text{
    border: 2px solid whitesmoke;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    text-align: center;
    padding: 5px;
    font-weight: bold;
}
.dateField{
   border: 2px solid whitesmoke;
   margin-top: -15px;
   height: 114px;
   width: 120px;
   border-left: 0px;

}
.dateField1{
    height: 114px;
    width: 120px;
    border-right: 2px solid whitesmoke;
}
.incentives-layout{
     border: 2px solid whitesmoke;
     margin-top: 20px;
     margin-left: 20px;
     height: 260px;
}
.incentivesTotal{
    margin-left: 140px;
    margin-top: 40px;
}
.total,.incent{
    font-weight: 700;
}
.total{
    margin-left: 55px;
}
.incent{
   margin-left:40px ;
}
.ruppes{
    font-weight: bold;
    font-size: 40px;
}
.circle{
    border: 2px solid whitesmoke;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-left: 150px;
}
.thisYear,.thisMonth{
    margin-left: 30px;
}
.thisYearMoney,.thisMonthMoney{
    margin-left: 40px;
    color: black;
    font-weight: 500;
    margin-top: 10px;
}
/* .calendar{
    margin-left: 50px;
    margin-top: 10px;
    color: black;
    margin-bottom: 10px;
} */
.Redlines{
    margin-top: 20px;
    height: 100px;
    width:10px;
    border: 1px solid rgb(228,103,120);
    background-color: rgb(228,103,120);
}
.Bluelines{
    margin-top: 20px;
    height: 100px;
    width:10px;
    border: 1px solid rgb(47,121,194);
    background-color: rgb(47,121,194);
}
.exp-cube{
   display: flex;
   flex-direction: row;
}
.cube{
    height:15px;
    width: 15px;
    background-color: aqua;
    margin-left: 40px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 2px;
}
.my-exp{
    background-color: rgb(120,144,249);
}
.pending-exp{
 background-color: rgb(249,219,131);
}
.aproved-exp{
    background-color: rgb(185,174,252);
}
.reimbursed{
    background-color: rgb(249,170,205);
}

.res{
    background-color: #7A9CEB;

}
.comm{
    background-color: #52D69A;

}
.arch{
    background-color: #FAA745;



}

.intr{
    background-color: #F29D9D;

}

.plus4{
    background-color:#a2a4af;
}

.plus5{
    background-color:#9cb8b666;

}
.others{
    background-color:#c6c482;

}
