/* .nav-logo{
    height:50px;
    margin-top: 5px;
    margin-left:40px;
} */
 .parent{
    position: relative;
 }

.search-layout{
    position:relative;
}
.notification-badge {
    position: absolute;
    top: 4px;
    right: 23px;
    background-color: red;
    color: white;
    border-radius: 40%;
    padding: 0px 6px;
    font-size: 11px;
}

/* .search-field{
    border: none;
    border-radius: 8px;
    width:300px;
    margin-left:100px;
    margin-top: 5px;
    padding: 8px;
    background-color: rgb(240, 238, 238);
} */
/* .search-icon{
    margin-left: -30px;
} */
.data-result{
    position: absolute;
    margin-left: 250px;
    height: 100px;
    width: 300px;

}
.off-icon-layout{
    display: flex;
}
.off-icon{
    margin-top: 3px;
    margin-right: 10px;
}

