.date-filter{
    position: absolute;
    right: 26px;
    box-shadow: 1px 7px 12px grey;
}
.date-range{
    position: relative;
    z-index: 9;
}

/* navbar buttons */

.apply-filter{
    padding: 6px 10px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 1px 1px 10px 1px lightgrey;
    border: none;
    color: #c89722;
    margin-right: 20px;
}

.calender-pic{
    width: 20px;
    margin-left: 10px;
}
.filter-wrapper{
    /* background-color: yellowgreen; */
    width: 150px;
    height: 40px;
    /* padding-top: 4px; */
    padding-right: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #eee ;
}
.filter-wrapper span:nth-last-child(2){
    /* background-color: yellowgreen; */
   font-size: medium;
   font-weight: 500;
   color: rgb(120, 116, 116);
}
.filter-wrapper span:last-child{
    background-color:rgb(240, 229, 229);
    border-radius: 10px;
    display: inline-block;
    padding-top: 2px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: small;
    font-weight: 500;
    color: rgb(120, 116, 116);
}

/* .filter-data{
    display: flex;
    max-width: 700px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 0;
    margin-bottom: 0;
}
.filter-data li{
    display: flex;
    list-style: none;
    padding: 0px 10px;
    background-color: gainsboro;
    margin-right: 10px;
    height: 30px;
    border-radius: 7px;
} */
.arrow{
    border: 0;
    color: #777;
    margin: 0 8px;
}

.delete-btn{
padding: 5px 15px;
border-radius: 7px;
border: 1px solid #eee;
background: #fff;
color: #777;
cursor: pointer;
}
.allocate-btn{
    padding: 5px 15px;
    border-radius: 7px;
    border: 1px solid #eee;
    background: #fff;
    color: #777; 
    cursor: pointer;
}
.btn-container {
    display: flex;
    margin-right: 10px;
    gap: 10px;
}
.allocate-btn.active:hover,
.delete-btn.active:hover {
    background: #f8a504 !important;
    color: #fff;
    border: 1px solid #f8a504;
}


.listItem.checkbox {
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxInput {
    cursor: pointer;
}
