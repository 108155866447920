/* .tkp-customers-layout{
  border: 1px solid grey;
  border-radius: 10px;
  width: 96%; 
  height:100%;
  background-color: white;
  margin: 2%;
} */

/* .tkp-navbar{
    display: flex;
    padding: 10px;
    margin-top: 11px;
} */

/* .tkp-search-user-layout{
    margin-top: 10px;
    margin-left: 20px;    
} */
.tkp-search-users-field{
    padding: 10px;
}
/* .tkp-toggle-btn-layout{
    border: 0px solid;
    height: 50px;
    width: 100px;
    background-color: #f2f2f2;
    display: flex;
    border-radius: 5px;
    margin-left: auto;
} */
/* .tkp-toggle-btn-card,.tkp-toggle-btn-list{
   margin: auto;
} */
.tkp-setting-slider-icon,.tkp-settings-icon{
    height: 25px;
    margin-top: 20px;
    margin-left: 40px;
}
/* .card-layout{
    margin-left: 40px;
    display: flex;

} */
/* .card{
    height: 280px;
    width: 320px;
    border: 0px solid grey;
    margin: auto;
    border-radius: 10px;
    margin-top:20px ;
    margin-left: 20px;
} */
.card-data{
    text-align: center;
}
/* .card-data-profile-layout{
    height: 80px;
    width: 80px;
    border: 0px solid grey;
    background-color: white;
    border-radius: 10px;
    margin-left: 105px;
    margin-top: 40px;
} */
.medal-layout{
    border: 1px solid grey;
    height: 40px;
    width: 40px;
    margin-top: -20px;
    margin-left: 50px;
    border-radius: 50%;
    background-color:white;
    position: relative;
}
.medal{
    margin-top: 10px;
}
.card-data-profile{
    height: 80px;
    width: 80px;
}
.card-data-profile-name{
    font-weight: bold;
    font-size: 20px;
    /* margin-top: 20px; */
}
.card-data-profile-years{
    font-size:small ;
    color: grey;
}
/* .card-data-profile-years,.card-data-profile-medal,.card-data-profile-ratings{
    margin-top: -10px;
} */
.pic{
    height: 50px;
    width: 50px;
    border: 0px solid ;
    border-radius: 5px;
    margin-right: 10px;
}
.table-head-row{
     height: 40px;
     border-bottom: 2px solid grey;
}
.tkp-head-data-p{
    margin-left: 60px;
    color:#979696;
    font-weight: bold;
    width: 250px;
}
.tkp-head-data-c{
    color:#979696;
    font-weight: bold;
    width: 120px;
}
.tkp-head-data-a{
    color:#979696;
    font-weight: bold;
    width: 180px;
}
.tkp-head-data-s{
    color:#979696;
    font-weight: bold;
    width: 300px;
}
.tkp-head-data-st{
    width: 200px;
    color:#979696;
    font-weight: bold;
}
.tkp-head-data-ab{
    width: 250px;
    color:#979696;
    font-weight: bold;
}
.tkp-head-cb{
    margin-left:50px;

}
.tkp-table-cb{
    margin-left: 50px;
    font-weight: bold;
}
.tkp-table-p{
    font-weight: bold;
    margin-left: 60px;
    width: 250px;

}
.tkp-table-c{
    font-weight: bold;
    color:#979696;
    width: 120px;
}
.tkp-table-a{
    font-weight: bold;
    color:#979696;
    width: 180px;
}
.tkp-table-s{
    font-weight: bold;
    color:#979696;
    width: 300px;


}
.tkp-table-st{
    font-weight: bold;
    width: 200px;

}
.tkp-table-ab{
    font-weight: bold;
    display: flex;
    width: 200px;
}
.tkp-table-dots{
    margin-left:70px;
}
.Caution-Deposite{
border: 0px solid ;
border-radius: 20px;
background-color: rgb(116, 223, 95);
width: 7vw;
color: white;
text-align: center;
}
.signed-contract{
    border: 0px solid ;
    border-radius: 20px;
    background-color: rgb(51, 192, 185);
    width: 7vw;
    color: white;
    text-align: center;
    }
/* .action-icons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 4vw;
} */
.dots-icon{
    height: 20px;
}
.assigned-layout{
display: flex;
}
/* .assigned-circle{
    border: 0px solid;
    background-color: rgb(241, 210, 151);
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    height: 40px;
    width: 40px;
} */
/* .list-layout,.card-layout{
    margin-bottom: 3%;
} */

/* .MuiCardContent-root{
    padding: none !important;
} */
/* Addding new search bar styles to inside layouts */
/* div{
    position: relative;
} */
/* .searchField-chield{
    width: 375px;
    padding: 7px 7px 7px 45px;
    border: 1px solid #eee !important;
} */
/* .searchIcon-chield{
    position: absolute;
    left: 20px;
    top: 10px;
} */
.clearIcon{
    position: absolute;
    right: 10px;
    top: 10px;

}

/*  spin icons  */
.spin-icon{
    color: orange;
}

.table-filter-info{
  padding : 15px;
}
.thead-primary tr th {
    background-color: #5a8dee;
    border-color: #5a8dee;
    color: #fff;
}

