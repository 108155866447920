.lead-nav-icons{
    width: 200px;
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* column-gap: 3px; */
}
.lead-nav-icons img{
    height: 25px;
}


.leadModal .ant-modal-header{
    border-radius: 10px;
}
.leadModal .ant-modal-content{
    border-radius: 10px;
    width: 700px;
    height: 800px;
}
.lead-modal-container{
    /* background-color: gray; */
    height: 90%;
     display: flex;
    flex-direction: column;
    row-gap: 30px;

}
.description-wrapper{
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
}
.description-wrapper img{
    height: 30px;
}
.lead-modal-wrapper{
    /* background-color: yellow; */
    text-align: right;
}

.items-wrapper{
    
    display: flex;
    flex-direction: column;
}
.noDataFoundBox1{
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 300px);
    width: calc(100vw - 350px);
}
.noDataFoundBox1 span{
    font-size: 20px;
    color: #f8a504;
} 
.myLeadsPopover .ant-popover-inner{
    border-radius: 10px;
}
.myLeadsPopover .ant-popover-title{
    text-align: center;
}
button[title="HTML mode"]{
    display: none !important;
}
button[title="Clear formatting"]{
    display: none !important;
}
/* option[value='3']{
display: none;
} */

.linkTypeElement{
    cursor:pointer;
    text-decoration:underline;
    text-decoration-color: rgb(17, 111, 233);
    font-size: larger;
    /* color:blue; */
}
.editIcon{
    cursor: pointer;
    color:"#F8A504";
    margin-left: "5px";
    font-size: "18px";
}
.editIcon:hover{
    color:"white";
    background-color: "#F8A504";

}

.callNowHead {
    flex: 0 1 150px;
    margin-right: 58px;
}

.tableRow .btn {
    border-radius: 0.5rem !important;
}

.callNowBtn {
    background-color: #2fa133 !important;
     /* #4caf50; */
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    flex: 0 1 30px;
    width: 58px;
    /* flex-direction: row; */
}


.callNowContainer {
    align-items: center;
}