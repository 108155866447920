body{
    margin: 0px;
    padding: 0px;
}
/* .createUser-layout{
 margin-top: 40px;
} */

/* .user-picture{
  border: 1px solid rgb(218, 217, 217);
  border-radius: 10px;
  height: 350px;
  width: 400px;
  background-color:white;
  margin-left: 80px;
 
} */
.user-picture-data{
    display: flex;
    flex-direction: column;

}
.User-details{
    border: 1px solid rgb(218, 217, 217);
    border-radius: 10px;
    height: 100%;
    width:96%;
    background-color: white;
    margin-bottom: 20px;

}
/* .user-picture-head, .user-details-head{
    border: 1px solid rgb(194, 190, 190);
    height: 50px;
    background-color:#f5f5f5;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
} */
.user-details-head{
    display: flex;
}
.user-details-text{
    font-size: 18px;
    font-weight:500;
    margin-left: 20px;
    color: black;
    margin-top: 10px;
}
/* .picture-text{
    font-size: 18px;
    font-weight:500;
    margin-left: 20px;
    color: black;
    position: relative;
    top: 10px;
} */

.user-details-activeBtn, .user-details-saveBtn{
   font-size: 18px;
   width: 100px;
   height: 30px;
   /* margin-left: 20px;
   margin-top: 5px; */
   margin-right: 20px;
   border: 1px solid;
   border-radius: 5px;
   color: white;
   height: 35px;
}
.user-details-btns{
    margin-left: 48%;
}
/* .user-details-activeBtn{
    background-color:rgb(147, 219, 75);

} */
.user-details-saveBtn{
    background-color:rgba(32, 151, 112, 0.945);

}
.input-field{
    padding: 9px;
    border: 1px solid rgb(218, 217, 217);
    border-radius:5px;
    background-color:#ffffff; 
}
.full-input-field{
    padding: 9px;
    border: 1px solid rgb(218, 217, 217);
    border-radius:5px;
    background-color: #ffffff; 

}



.image-holder{
    margin: auto;
}
.image{
    height: 200px;
    width:200px;
    border-radius: 50%;
}
/* #input[type="file"]{
    display: none;
} */

.image-upload{
   margin: auto;
   width: 200px;
   height: 35px;
   background-color:orange;
   color: white;
   border-radius: 4px;
   cursor: pointer;
   margin-top: 30px;
   text-align: center;
}
/* .form-group-left{
    margin-left: 20px;
    margin-top: 25px;
} */
/* .form-group-right{
    margin-top: 25px;

} */
/* .full-form-group{
    margin-left: 20px;
    margin-top: 25px;

} */