
.customers-layout{
    background-color:#e6e6e6 ;
    height: 100%;
}
.customers-head-label{
    display: flex;
    flex: 100%;
    height: 50px;
    background-color:#f5f5f5;
}
/* .customer-font{
    font-size: 1vw;
    margin-top: 10px;
    margin-left: 20px;
    font-weight: bold;
} */
.add-project-btn{
    display: flex;
    border: 0px solid ;
    border-radius: 5px;
    background-color: rgb(255, 234, 196);
    margin-top: 10px;
    height: 30px;
    width: 170px;
    color: orange;
}

.add-project-text{
    font-size: 0.8vw;
    margin-top: 3px;
    margin-left: 5px;
}
/* .add-icon{
    margin-left:5px;
} */
/* .back-to-customer-font{
    font-weight: bold;
    margin-top: 10px;
    margin-left: 20px;
} */
.back-to-customer-list{
    display: flex;
    flex-direction: row;
    margin-top: 3px;
}
.back-customer-head{
    margin-left:-70vw;
}
.customer-list-layout{
    border:  0px solid;
    height: 100%;
    width:  92%;
    background-color:#ffff;
    margin-top:3%;
    margin: 4%;
    border-radius: 10px;

}
.customer-list-layout-head{
    border: 0px solid;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    background-color:#ffff;
    display: flex;
}

.search-customers-field{
    width: 350px;
    border: none;
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-left: 10px;
}
.serach-customers-layout{
    margin-top: 20px;
    margin-left: 20px;
}
.customers-toggle-btn{
    border: 0px solid;
    width: 100px;
    background-color: #f2f2f2;
    margin-top:20px;
    margin-left: 52%;
    display: flex;
    border-radius: 5px;
}
.settings-icon,.setting-slider-icon{
    height: 20px;
}

.app-icon,.list-icon{
    position: relative;
    height: 20px;
    margin: auto;
}

.table-head{
    display: flex;
    margin: auto;
    align-items: center;
}
.table-data-row{
    display: flex;
    height: 90px;
    align-items: center;
    border:1px solid whitesmoke;
    border-top:1px solid whitesmoke ;
}
.head-data-cb{
    margin-left:110px;
   color:#979696;
   font-weight: bold;
}
.head-data-fn{
    margin-left: 100px;
    width: 300px;
   color:#979696;
   font-weight: bold;
}
.head-data-phone{
   width: 200px;
   color:#979696;
   font-weight: bold;
}
.head-data-a{
    width: 200px;
   color:#979696;
   font-weight: bold;
}
.head-data-pc{
    width:150px;
   color:#979696;
   font-weight: bold;
}
.head-data-ra{
   width: 250px;
   color:#979696;
   font-weight: bold;
}
.head-data-ac{
   width: 150px;
   color:#979696;
   font-weight: bold;
}

.table-data-cb{
    margin-left: 50px;
    font-weight: bold;
}
.table-data-fn{
    margin-left: 45px;
    width: 300px;
    font-weight: bold;
}
.table-data-phone{
    width: 200px;
    font-weight: bold;
}
.table-data-a{
    width: 200px;
    font-weight: bold;
}
.table-data-pc{
    width: 150px;
    font-weight: bold;
}
.table-data-ra{
    width: 250px;
    font-weight: bold;
}
.table-data-ac{
    width: 150px;
 
}
.table-data-cb{
    margin-left: 3vw;
    font-weight: bold;
}
/* .vertical-line{
    margin-top: 4px;
    border: 1px solid rgb(218, 214, 214);
} */
/* .customers-edit-icon,.customers-trash-icon{
    height:2vh;
} */
/* .action-icons{
    width: 50px;
    margin-left: 5px;
} */
/* .right-angle-icon{
    height: 2vh;
    margin-left: 2vw;
} */

/* .no-data-layout{
    background-color: orange;
    margin-top:25vh ;
    margin-left: 35vw;
} */
/* .no-data-found-text{
 margin-left: 4.5vw;
 margin-top: 1vh;
 font-size: 1.5vw;
 color: orange;
}
.no-data-img{
  
} */
.item-email{
    font-size: small;
    color: grey;
}

/* Create Customers Layout Styles  */

.add-tkp-text {
    margin-top: 5px;
    margin-left: 10px;
 }
 
 .buttons{
     font-size: 15px;
     cursor: pointer;
     opacity: 0.6;
     margin-left: 40px;
     border: 0;
     outline: 0;
     color: rgb(0, 0, 0);
 }
 
 /* Customers Styles  */
 .addCustomers-navbar{
     padding: 20px; 
     border-bottom:1px solid rgb(194, 194, 194) ;
 }
/*Antd Tab styles  */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: orange;
}
.ant-tabs-tab.ant-tabs-tab-active :hover{
    color:none !important;
}

/* .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    margin-left: 60px;
} */