
.appointments-avatar-list{
    display: flex;
    align-items: center;
}

/* .list-wrapper{
    width: 55%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
} */
/* .listdetails-wrapper{
    background-color: blue;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 5%;
    overflow-y: auto;
} */
.avatar-list-wrapper{
    margin-bottom: 10px;
}

/*date- filter*/
.contentNavbar{
    display: flex;
    padding: 15px 20px;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid #eee; */
}
.date-filter{
    position: absolute;
    right: 26px;
    box-shadow: 1px 7px 12px grey;
}
.date-range{
    position: relative;
    z-index: 9;
}

/* navbar buttons */

.apply-filter{
    padding: 6px 10px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 1px 1px 10px 1px lightgrey;
    border: none;
    color: #c89722;
    margin-right: 20px;
}

.calender-pic{
    width: 20px;
    margin-left: 10px;
}