.customstepswrapper{
    /* background-color: yellowgreen; */
    width: 100%;
    padding-left: 10px;
    display: flex;
    flex-direction: row;

}
.datewrapper{
    /* background-color: yellow; */
    box-sizing: border-box;
    width: 105px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid rgb(249, 120, 120);
}
.datewrapper .ant-divider-vertical{
  
    height:100%;
    top:-0.5em;
    margin: 0;
    border-left:1px solid rgb(249, 120, 120);
}

.stepwrapper p{
    /* background-color: pink; */
    text-align: center;
    color: rgb(164, 161, 157);
    margin-top: 2px;
}