
.users-layout{
    background-color:#e6e6e6 ;
}
.sw-head-label{
    display: flex;
    flex: 100%;
    height: 50px;
    width: 87vw;
    background-color:#f5f5f5;
}
/* .sw-font{
    font-size: 18px;
    margin-top: 10px;
    margin-left: 20px;
    font-weight: bold;
} */
/* .sw-btn{
    display: flex;
    border: 1px solid ;
    border-radius: 8px;
    margin-top: 10px;
    height: 30px;
    color:white !important;
    height: 35px;
    width: 150px;
} */

.sw-text{
    margin-top: 5px;
}
.rating-text{
    font-weight: bold;
}
