.appointmentcards-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.card-wrapper {
    /* background-color: yellowgreen; */
    width: 90%;
    border-radius: 10px;
    box-shadow: 1px 1px 1px gray;

}

.card-wrapper .ant-card-body {
    padding: 0px;
}

.appointment-card-details {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

